import React from "react";
import styled from "styled-components";

const StyledMarker = styled.li`
  width: 12px;
  height: 12px;
  margin: 0 2px;
  padding: 0;
  border-radius: 50%;
  vertical-align: top;
  display: inline-block;
  background-color: ${props => props.active ? 'red' : '#bfbfbf'};
  cursor: pointer;
`;

export default ({ active, onClick }) =>
  <StyledMarker active={active} onClick={onClick} />;