import React from 'react';
import styled from 'styled-components';
import { media } from '../../../utils/styles';
import Link from '../../../elements/Link';

const LoginFrame = styled.iframe`
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  height: 361px;

  html {
    overflow: hidden;
  }
`;

const Wrapper = styled.div`
  padding-right: 10px;
  padding-left: 10px;
  text-align: left;
  position: relative;
  z-index: 1;

  ${media.tablet`
    padding-right: 54px;
    padding-left: 54px;
  `}

  ${media.desktop`
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    padding-top: 60px;
  `}
`;

const SignupHeader = styled.h3`
  font-family: ${props => props.theme.headerFontFamily};
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.075em;
  color: ${props => props.theme.primary};
  text-transform: uppercase;
  text-align: center;
  margin: 10px 0 20px 0;

  ${media.tablet`
    font-size: 18px;
  `};
`;

const FormWrapper = styled.div`
  padding: 14px;
  background: #fff;
  border-radius: 25px;
  border: 1px solid #eee;
  height: 515px;

  ${media.tablet`
    width: 400px;
    margin: 0 auto;
    padding: 16px;
  `};

  ${media.desktop`
    margin: 0;
  `};
`;

const Terms = styled.p`
  font-family: ${props => props.theme.sansSerifFontFamily};
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  letter-spacing: 0;
  color: #8c8c8c;
  width: auto;
  margin: 0;
  padding: 0;

  a {
    color: ${props => props.theme.primary};
    text-decoration: none;
  }
`;

export default () => <Wrapper>
  <FormWrapper>
    <SignupHeader>Start your 2 week free trial</SignupHeader>
    <LoginFrame src={`${process.env.GATSBY_APP_URL}/signup_public.html`} />
    <Terms>
      By clicking the button above, you agree to the{" "}
      <Link to="/terms" label="Terms of User">Terms of Use.</Link>
      <br />View our <Link to="/privacy" label="Privacy Policy">Privacy Policy.</Link>
    </Terms>
  </FormWrapper>
</Wrapper>