import React from 'react';
import styled from 'styled-components';
import { media } from '../../../utils/styles';
import Heading from '../../../elements/Heading';
import Button from '../../../elements/Buttons';
import SignUpArrow from '../../Icons/SignUpArrow';
import SignUpArrowLeft from '../../Icons/SignUpArrowLeft';
import PricingCard from './PricingCard';
import Section from '../../../elements/Section';
import Wrapper from '../../../elements/Wrapper';
import Tick from '../../../images/pink-tick.png';
import Heart from '../../../images/nubabi-heart.png';
import { StaticQuery, graphql } from 'gatsby';

const StyledButton = styled(Button) `
  ${media.tablet`
    margin-top: 0px;
  `}
`

const Cards = styled.ul`
  list-style-type: none;
  width: 100%;
  text-align: center;
  margin-top: 50px;
  padding: 0;

  ${media.tablet`
    margin-bottom: 50px;
  `}
`;

const FeaturesWrapper = styled.div`
  margin: 0 auto 40px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;

  ${media.tablet`
    width: 768px;
    margin: 0 auto 80px;
  `}

  ${media.desktop`
    width: 860px;
  `}
`;


const Container = styled.div`
  border: 2px solid #edf0f9;
  padding: 0 20px 20px 20px;
  &::before {
    content: '';
    display: block;
    height: 80px;
    width: 80px;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url(${Heart});
    margin-top: -40px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const FeaturesHeader = styled.p`
  text-transform: uppercase;
  font-family: ${props => props.theme.headerFontFamily};
  font-weight: 700;
  font-size: 25px;
  color: ${props => props.theme.primary};
  margin-top: 15px;

  ${media.tablet`
    font-size: 30px;
  `}
`;

const Features = styled.ul`
  list-style: none;
  margin-left: 10px;
  padding: 0;

  ${media.tablet`
    margin-left: 80px;
  `}
`;

const Feature = styled.li`
  text-align: left;
  padding: 0;
  margin: 0;
  font-family: ${props => props.theme.sansSerifFontFamily};
  font-size: 17px;
  line-height: 26px;
  font-weight: 300;
  color: #454d57;

  &::before {
    content: '';
    display: block;
    height: 15px;
    width: 15px;
    margin-left: -20px;
    background-image: url(${Tick});
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    margin-top: 5px;

    ${media.tablet`
      height: 20px;
      width: 20px;
      margin-left: -35px;
    `};
  }

  ${media.tablet`
    font-size: 20px;
  `};
`;

const OnePrice = styled.p`
  text-transform: uppercase;
  font-family: ${props => props.theme.headerFontFamily};
  font-weight: 700;
  font-size: 20px;
  color: ${props => props.theme.primary};
  margin-top: 40px;

  ${media.tablet`
    font-size: 25px;
  `}
`;

const ArrowRight = styled(SignUpArrow)`
  display: none;

  ${media.tablet`
    width: 220px;
    display: block;
    position: absolute;
    margin-left: 50px;
    margin-top: -30px;
  `}

  ${media.desktop`
    width: 257px;
    margin-left: 130px;
    margin-top: -50px;
  `}
`;

const ArrowLeft = styled(SignUpArrowLeft)`
  width: 200px;
  position: relative;
  z-index: 200;
  margin-left: 50px;
  margin-top: 30px;

  ${media.tablet`
    position: absolute;
    bottom: 70px;
    left: 50%;
    display: none;
  `}

  ${media.smallDesktop`
    display: none;
  `}
`;

export default () =>
  <StaticQuery
    query={graphql`
      query {
        contentfulHomePage {
          id
          pricingHeader
          pricingFeatures {
            id
            ... on ContentfulHomePagePricingOffering {
              displayOrder
              feature {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
        allContentfulHomePagePricing(sort: { fields: [displayOrder]}, filter: { node_locale: { eq: "en" } })
        {
          edges {
            node {
              id
              displayOrder
              name
              amount
              pricingFeatures
              feature
            }
          }
        }
      }
    `}
    render={({
      contentfulHomePage: {
        pricingHeader: header,
        pricingFeatures
      },
      allContentfulHomePagePricing: {
        edges: pricing,
      }
    }) => (
      <Section id="our-subscriptions">
        <Wrapper centerText={true}>
          <Heading>{header}</Heading>
          <Cards>
            {
              pricing.map(({ node: pricingInfo }) => (
                <PricingCard
                  key={`pricecard_${pricingInfo.id}`}
                  header={pricingInfo.name}
                  amount={pricingInfo.amount}
                  pricingFeatures={pricingInfo.pricingFeatures}
                  feature={pricingInfo.feature} />
              ))
            }
          </Cards>
          <FeaturesWrapper>
            <Container>
              <FeaturesHeader>WHY NUBABI?</FeaturesHeader>
              <Features>
                {
                  pricingFeatures.sort((a, b) => { if (a.displayOrder > b.displayOrder) { return 1; } if (a.displayOrder < b.displayOrder) { return -1; } return 0; }).map(feature => (
                    <Feature key={`pricingFeature_${feature.displayOrder}`} dangerouslySetInnerHTML={{ __html: feature.feature.childMarkdownRemark.html }} />
                  ))
                }
              </Features>
              <OnePrice>ONE family price. Sign up siblings for FREE.</OnePrice>
            </Container>
          </FeaturesWrapper>
          <ArrowRight />
          <StyledButton href={process.env.GATSBY_SIGNUP_URL}>
            Sign up for a Free Trial
          </StyledButton>
          <ArrowLeft />
        </Wrapper>
      </Section>
    )}
  />;
