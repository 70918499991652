import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

const ResponsiveBackgroundImage = styled.picture`
  display: ${props => props.width >= 1024 ? 'none' : 'inline-block'};
  transition: visibility 0s, opacity 500ms ease;
  width: 100%;
  opacity: 0;

  > img {
    width: 100%;
  }
`;

export default class Background extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: 1026 };
    this.updateWidth = this.updateWidth.bind(this);
    this.setBackground = this.setBackground.bind(this);
  }
  componentDidMount() {
    this.setState({ width: window.innerWidth });
    window.addEventListener('resize', this.updateWidth);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth);
  }
  updateWidth() {
    this.setState({ width: window.innerWidth });
    this.setBackground();
  }
  setBackground() {
    const img = ReactDOM.findDOMNode(this.refs.img);
    const wrapper = ReactDOM.findDOMNode(this.refs.wrapper);
    let src = typeof img.currentSrc !== 'undefined' ? img.currentSrc : img.src;
    if (this.state.width >= 1024) {
      this.props.setBackground(src);
      wrapper.style.opacity = 0;
    } else {
      this.props.removeBackground();
      wrapper.style.opacity = 1;
    }
  }
  render() {
    return (
      <ResponsiveBackgroundImage
        width={this.state.width}
        ref="wrapper"
        onLoad={this.setBackground}
      >
        <source
          media="(max-width: 767px)"
          srcSet="https://images.contentful.com/3nvu4b9mcmye/1dKqvBe1YeQCaQs4QaQWUA/5243f1ff8e4a5d3072274943a9b7764d/header-background2.jpg?w=660&q=50"
        />
        <source
          media="(max-width: 1024px)"
          srcSet="https://images.contentful.com/3nvu4b9mcmye/3LhcV69mve6KUIQwUC6ws6/25367764affc2adb0117c9cbf26be9d9/header-background-tablet.jpg?w=1536&q=50"
        />
        <img
          src="https://images.ctfassets.net/3nvu4b9mcmye/5XtqSIyyKAWWcmKuo8mQU/5298969aea1f9ce7d9e090396ea993b1/header-background-desktop2.jpg?w=2000&h=1334&q=50"
          alt="Welcome to Nubabi"
          ref="img"
        />
      </ResponsiveBackgroundImage>
    )
  }
}