import React from 'react';
import styled from 'styled-components';

const StyledIcon = styled.svg`
  .cls-1 {
    fill: ${props => props.theme.primary};
    transition: fill 0.1s linear;
  }
  .cls-2 { fill: #fff; }
`;

const Wrapper = styled.span`
  cursor: pointer;
`;

export default ({ onClick, className }) => (
  <Wrapper onClick={onClick}>
    <StyledIcon
      viewBox="0 0 42.34 42.34"
      className={className}
    >
      <circle className="cls-1" cx="21.17" cy="21.17" r="21.17" transform="translate(-4.3 36.91) rotate(-76.72)" />
      <g transform="rotate(45, 21.34, 21)">
        <rect className="cls-2" x="8.34" y="20" width="26" height="2" rx="1" ry="1" />
        <path className="cls-2" d="M20.34,33V9a1,1,0,0,1,1-1h0a1,1,0,0,1,1,1V33a1,1,0,0,1-1,1h0A1,1,0,0,1,20.34,33Z" />
      </g>
    </StyledIcon>
  </Wrapper>
)