import React from 'react';
import Layout from '../components/Layout';
import HomePageTemplate from '../components/HomePageTemplate';
import { pseudoElementScript } from '../utils/headerScripts';
import { compose, lifecycle } from 'recompose';

const IndexPage = ({ location: { href } }) =>
  <Layout canonicalUrl={href}>
    <HomePageTemplate />
  </Layout>

export default compose(
  lifecycle({
    componentDidMount() {
      pseudoElementScript();
    }
  })
)(IndexPage);
