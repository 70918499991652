import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Seo from '../Seo';
import Intro from './Intro';
import Features from './Features';
import Experts from './Experts';
import BabyBenefits from './BabyBenefits';
import TheApp from './TheApp';
import Pricing from './Pricing';
import Testimonials from './Testimonials';
import HeadStart from './HeadStart';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulHomePage {
          pageMetaData {
            title
            description
          }
        }
      }
    `}
    render={({ contentfulHomePage: homePage }) => {
      return <>
        <Seo
          title={homePage.pageMetaData.title}
          description={homePage.pageMetaData.description}
          contentType={'website'}
          schema={'WebSite'}
        />
        <Intro />
        <Features />
        <Experts />
        <BabyBenefits />
        <TheApp />
        <Pricing />
        <Testimonials />
        <HeadStart />
      </>;
    }}
  />
);