import styled from "styled-components";
import { media } from "../utils/styles";

export default styled.p`
  font-family: ${props => props.theme.sansSerifFontFamily};
  font-size: 17px;
  line-height: 25px;
  font-weight: 300;
  letter-spacing: 0;
  color: #4d4d4d;
  padding: 30px 0;
  text-align: center;
  margin: 0 auto;

  ${media.tablet`
    max-width: 600px;
  `}

  ${media.desktop`
    max-width: 650px;
    font-size: 20px;
    line-height: 28px;
  `}

  ${media.giant`
    max-width: 800px;
  `}
`;