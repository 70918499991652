import React from 'react';
import styled from 'styled-components';

const StyledIcon = styled.svg`
  .copy {
    font-size:24px;
    fill: #4d4d4d;
    font-family: Marydale-Bold, Marydale;
    font-weight: 700;
  }

  .line {
    fill:none;
    stroke: #4d4d4d;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
  }
`;

const Wrapper = styled.span``;

export default ({ onClick, className }) => (
  <Wrapper onClick={onClick}>
    <StyledIcon
      viewBox="0 0 283.61 103.05"
      className={className}
    >
      <text className="copy" transform="translate(77.35 22.94)">
        <tspan>Sign up here first to</tspan>
        <tspan x="26.28" y="31">access the app!</tspan>
      </text>
      <path className="line" d="M2.45,1s17.6,31.46,61.28,27" />
      <polyline className="line" points="16.52 2.38 2.46 1 1 13.92" />
    </StyledIcon>
  </Wrapper>
)