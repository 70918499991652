import React from 'react';
import styled from 'styled-components';
import theme from '../../utils/theme';

const StyledIcon = styled.svg`
  height: 87;
  width: 87;
`;

const Wrapper = styled.span``;

export default ({ onClick, className }) => (
  <Wrapper onClick={onClick}>
    <StyledIcon viewBox="0 0 87 87" className={className}>
      <g fill="none" fillRule="evenodd">
        <circle cx="43.5" cy="43.5" fill={theme.primary} r="43.5" />
        <text
          fill="#fff"
          fontFamily="Marydale-Bold, Marydale"
          fontSize="15"
          fontWeight="bold"
        >
          <tspan x="13" y="32">
            Best deal
          </tspan>
          <tspan x="27.4075" y="50">
            50%
          </tspan>
          <tspan x="29.0475" y="68">
            Off!
          </tspan>
        </text>
      </g>
    </StyledIcon>
  </Wrapper>
);
