import React from "react";
import styled from "styled-components";
import { media } from "../../../utils/styles";
import Image from "gatsby-image";
import Modal from "../../../elements/Modal";

const StyledModal = styled(Modal)`
  padding: 25px 10px 25px 25px;
  text-align: left;

  ${media.tablet`
    width: 689px;
    padding: 36px 10px 36px 41px;
    border-radius: 25px;
    box-shadow: 3px 3px 11px 0 #a1a1a1;
    margin-left: 0;
    margin-right: 0;
    max-width: none;
  `}

  ${media.desktop`
    max-width: 960px;
    margin: 0 auto;
    width: 760px;
    min-height: 558px;
  `}
`;

const StyledImage = styled(Image)`
  width: 170px;
  display: block;
`;

const Name = styled.h4`
  font-family: ${props => props.theme.sansSerifFontFamily};
  font-size: 19px;
  line-height: 25px;
  font-weight: 400;
  letter-spacing: 0;
  color: ${props => props.theme.primary};
  margin: 18px 0 11px;
`;

const MobileOnly = styled.div`
  display: inline-block;

  ${media.tablet`
    display: none;
  `}
`;

const ExpertName = styled.h4`
  font-family: ${props => props.theme.sansSerifFontFamily};
  font-size: 16px;
  line-height: 25px;
  font-weight: 400;
  letter-spacing: 0;
  color: ${props => props.theme.primary};
  margin: 0;

  ${media.tablet`
    display: none;
  `}
`;

const Discipline = styled.h5`
  font-family: ${props => props.theme.sansSerifFontFamily};
  font-size: 14.5px;
  line-height: 21.5px;
  font-weight: 300;
  letter-spacing: 0;
  color: #4d4d4d;
  margin: 0;
`;

const Info = styled.div`
  display: none;

  ${media.tablet`
    width: 170px;
    display: inline-block;
    float: left;
  `}
`

const Body = styled.div`
  margin-top: 20px;
  overflow-y: auto;
  padding-right: 20px;
  height: 100%;

  p {
    font-family: ${props => props.theme.sansSerifFontFamily};
    font-size: 14px;
    line-height: 21px;
    font-weight: 300;
    letter-spacing: 0;
    color: #333;
    margin: 0 0 21px;
  }

  blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    padding: 20px 20px 5px 45px;
    margin: 0 0 20px;
    p {
      font-size: 15px;
      line-height: 22px;
      &:first-child {
        margin-top: -60px;
      }
    }
    &:before {
      content: '\\201C';
      font-family: Georgia, serif;
      font-size: 60px;
      font-weight: bold;
      color: #999;
      position: relative;
      margin-left: -37px;
      margin-top: -10px;
    }
  }

  ${media.tablet`
    width: 433px;
    display: inline-block;
    float: left;
    margin-left: 33px;
    margin-top: 0;
  `}

  ${media.desktop`
    width: 495px;
  `}
`;

export default ({ active, name, discipline, image, biography, onToggle }) => (
  <StyledModal active={active} onToggle={onToggle}>
    <Info>
      {image &&
        <StyledImage title={image.title} alt={image.description} fluid={image.fluid} />}
      <Name>{name}</Name>
      <Discipline>{discipline}</Discipline>
    </Info>
    <MobileOnly>
      <ExpertName>{name}</ExpertName>
      <Discipline>{discipline}</Discipline>
    </MobileOnly>
    <Body dangerouslySetInnerHTML={{ __html: biography }} />
  </StyledModal>
)