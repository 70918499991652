import React from "react";
import Image from "gatsby-image";
import styled from "styled-components";
import { media } from "../../../utils/styles";

const Wrapper = styled.div`
  padding: 0 5px;
`;

const Card = styled.div`
  text-align: center;
  flex: 1;
  background: #00a0e1;
  border-radius: 12px;
  margin-top: 60px;
  padding-bottom: 40px;
  height: 360px;

  ${media.tablet`
    height: 330px;
  `}
`;

const Name = styled.span`
  font-family: marydale,Helvetica,Arial,sans-serif;
  font-size: 24px;
  line-height: 24px;
  font-weight: 300;
  letter-spacing: 0;
  color: #4d4d4d;
  display: block;
`;

const Testimonial = styled.p`
  font-family: 'bree-serif',Helvetica,Arial,sans-serif;
  font-size: 17px;
  line-height: 26px;
  font-weight: 300;
  letter-spacing: 0;
  color: #fff;
  padding-top: 12px;
  margin: 0 10px;
  padding: 0 20px 36px;

  ${media.tablet`
    margin-bottom: 28px;
  `}

  ${media.desktop`
    font-size: 22px;
    line-height: 28px;
    width: 678px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    margin-bottom: 0;
  `}
`;

const StyledImage = styled(Image) `
  position: relative;
  margin-top: -60px;
  border-radius: 50%;
  border: 6px solid #fff;
`;

export default ({ name, avatar, testimonial }) => (
  <Wrapper>
    <Card>
      {avatar &&
        <StyledImage title={avatar.title} alt={avatar.description} fixed={avatar.fixed} />}
      <Testimonial>&ldquo;{testimonial}&rdquo;</Testimonial>
      <Name>{name}</Name>
    </Card>
  </Wrapper>
);