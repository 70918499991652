import React from 'react';
import styled from 'styled-components';

const StyledIcon = styled.svg`
  .copy { font-size: 24px; fill:#4d4d4d; font-family: Marydale-Bold, Marydale; font-weight:700; }
  .line { fill: none; stroke:#4d4d4d; stroke-linecap: round; stroke-linejoin :round; stroke-width:2px; }
`;

const Wrapper = styled.span``;

export default ({ onClick, className }) => (
  <Wrapper onClick={onClick}>
    <StyledIcon
      viewBox="0 0 304.11 57.82"
      className={className}
    >
      <text className="copy" transform="translate(127.55 20.4)">
        <tspan>2 week unlimited</tspan>
        <tspan x="35.62" y="24">free trial!</tspan>
      </text>
      <path className="line" d="M1.6,21.65S57.75,71,124.81,43.1" />
      <polyline className="line" points="1.94 34.91 1 20.79 15.12 19.85" />
    </StyledIcon>
  </Wrapper>
);
