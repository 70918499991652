import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Intro from './Intro';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulHomePage {
          tagLine
          introSubHeading
          introDescription {
            introDescription
          }
        }
      }
    `}
    render={({
      contentfulHomePage: { tagLine, introSubHeading, introDescription: description }
    }) => (
      <Intro
        tagLine={tagLine}
        description={description.introDescription}
        introSubHeading={introSubHeading}
      />
    )}
  />
);
