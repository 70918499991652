import React from 'react';
import styled from 'styled-components';
import { media } from '../../../utils/styles';
import Heading from '../../../elements/Heading';
import SubHeading from '../../../elements/SubHeading';
import Section from '../../../elements/Section';
import Wrapper from '../../../elements/Wrapper';
import { StaticQuery, graphql } from 'gatsby';

const Image = styled.picture`
  > img {
    width: 100%;
    margin: 40px 0 0;
  }

  ${media.tablet`
    > img {
      margin-top: 187px;
      padding: 0 40px;
    }
  `}

  ${media.desktop`
    > img {
      width: 838px;
      margin-top: 174px;
      padding: 0 60px;
    }
  `}
`;

const StyledSubHeading = styled(SubHeading)`
  ${media.tablet`
    position: absolute;
    left: 50%;
    margin: 0 auto;
    padding: 0;
    width: 574px;
    margin-left: -287px;
    top: 163px;
  `}

  ${media.desktop`
    font-size: 20px;
    line-height: 28px;
    font-weight: 300;
    width: 730px;
    margin-left: -365px;
    top: 220px;
  `}
`;

export default () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulHomePage {
          babyBenefitsHeading
          babyBenefitsSubHeading {
            babyBenefitsSubHeading
          }
          babyBenefitsImage {
            title
            description
            fluid(maxWidth: 660) {
              ...GatsbyContentfulFluid
            }
          }
          babyBenefitsImageLarge {
            title
            description
            fluid(maxWidth: 1677) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    `}
    render={({
      contentfulHomePage : {
        babyBenefitsHeading: header,
        babyBenefitsSubHeading: { babyBenefitsSubHeading: subHeader },
        babyBenefitsImage: image,
        babyBenefitsImageLarge: imageLarge
      }
    }) => (
      <Section id="bundle-of-benefits">
        <Wrapper centerText={true}>
          <Heading>{header}</Heading>
          <Image>
            <source srcSet={image.fluid.srcSet} media="(max-width: 768px)" />
            <img
              sizes={imageLarge.fluid.srcSet}
              src={imageLarge.fluid.src}
              alt={image.description}
              title={image.title}
            />
          </Image>
          <StyledSubHeading>{subHeader}</StyledSubHeading>
        </Wrapper>
      </Section>
    )}
  />
);
