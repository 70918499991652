import React from 'react';
import styled from 'styled-components';
import { media } from '../../../utils/styles';
import Heading from '../../../elements/Heading';
import { WhiteButton } from '../../../elements/Buttons';
import SignUpArrowLeft from '../../Icons/SignUpArrowLeft';
import Section from '../../../elements/Section';
import Wrapper from '../../../elements/Wrapper';
import Tick from '../../../images/blue-tick.png';
import { StaticQuery, graphql } from 'gatsby';

const HeadStartSection = styled(Section)`
  &::after {
    content: '';
    width: 100%;
    height: 75px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    background: ${props => props.theme.primary};
    z-index: 0;

    ${media.tablet`
      text-align: center;
      height: 82px;
    `}

    ${media.desktop`
      height: 100px;
    `}
  }
`;

const HeadStartWrapper = styled(Wrapper)`
  text-align: center;

  ${media.tablet`
    text-align: left;
  `}
`;

const StyledHeading = styled(Heading)`
  color: #fff;
  max-width: 295px;
  margin-bottom: 46px;

  ${media.tablet`
    width: 385px;
    margin-bottom: 35px;
  `}

  ${media.desktop`
    font-size: 40px;
    line-height: 45px;
    width: auto;
    margin-bottom: 45px;
  `}
`;

const List = styled.ul`
  list-style-type: none;
  margin: 0 20px 45px 20px;
  padding: 0;
  position: relative;
  z-index: 3;
  width: 300px;

  ${media.tablet`
    margin-bottom: 38px;
    margin: 0 auto 45px auto;
  `}

  ${media.desktop`
    margin-bottom: 49px;
  `}
`;

const Item = styled.li`
  margin: 0 0 0 20px;
  padding: 0 0 0 10px;
  font-family: 'bree-serif', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;
  letter-spacing: 0;
  color: #333;
  position: relative;
  text-align: left;

  ${media.desktop`
    font-size: 20px;
    line-height: 30px;
  `} &::before {
    content: '';
    display: block;
    height: 20px;
    width: 20px;
    margin-left: -30px;
    background-size: contain;
    background-image: url(${Tick});
    background-repeat: no-repeat;
    position: absolute;
    margin-top: 7px;
  }
`;

const Picture = styled.picture`
  > img {
    position: relative;
    z-index: 1;
    width: 250px;
    margin-top: 50px;
  }

  ${media.tablet`
    > img {
      margin-top: -35px;
      width: 237px;
    }
  `}

  ${media.desktop`
    > img {
      margin-top: -100px;
      width: 404px;
    }
  `}
`;

const ArrowLeft = styled(SignUpArrowLeft)`
  display: none;

  ${media.tablet`
    display: inherit;
    width: 280px;
    position: absolute;
    margin-top: 20px;
    z-index: 2;
    margin-left: 150px;
  `}

  ${media.desktop`
    margin-left: 150px;
  `}
`;

export default () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulHomePage {
          headStartHeader
          headStartReasons
          headStartImageSmall {
            title
            description
            fluid(maxWidth: 660) {
              ...GatsbyContentfulFluid
            }
          }
          headStartImageMedium {
            title
            description
            fluid(maxWidth: 475) {
              ...GatsbyContentfulFluid
            }
          }
          headStartImageLarge {
            title
            description
            fluid(maxWidth: 808) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    `}
    render={({
      contentfulHomePage: {
        headStartHeader: header,
        headStartReasons: reasons,
        headStartImageSmall: imageSmall,
        headStartImageMedium: imageMedium,
        headStartImageLarge: imageLarge,
        signupUrl
      }
    }) => (
      <HeadStartSection id="head-start" backgroundColor={'#66c6ed'}>
        <HeadStartWrapper>
          <StyledHeading>{header}</StyledHeading>
          <List>
            {
              reasons.map((reason) => (
                <Item key={reason}>{reason}</Item>
              ))
            }
          </List>
          <WhiteButton href={process.env.GATSBY_SIGNUP_URL}>Sign Up</WhiteButton>
          <Picture>
            <source
              srcSet={imageLarge.fluid.src}
              media="(min-width: 1024px)"
            />
            <source
              srcSet={imageMedium.fluid.src}
              media="(min-width: 768px)"
            />
            <img srcSet={imageSmall.fluid.src} title={imageSmall.title} alt={imageSmall.description} />
          </Picture>
          <ArrowLeft />
        </HeadStartWrapper>
      </HeadStartSection>
    )}
  />
);
