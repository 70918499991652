import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Testimonials from './Testimonials';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulTestimonial(
          filter: { published: { eq: true }, node_locale: { eq: "en" } }
        ) {
          edges {
            node {
              id
              name
              testimonial {
                testimonial
              }
              avatar {
                title
                description
                fixed(width: 124) {
                  ...GatsbyContentfulFixed
                }
              }
            }
          }
        }
      }
    `}
    render={({ allContentfulTestimonial: { edges: testimonials } }) => (
      <Testimonials testimonials={testimonials} />
    )}
  />
);

