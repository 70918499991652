import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Experts from './Experts';

export default () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulHomePage {
          expertsHeader {
            expertsHeader
          }
          expertsSubHeader {
            expertsSubHeader
          }
          experts {
            id
            name
            homePageDisplayOrder
            discipline {
              name
            }
            biography {
              childMarkdownRemark {
                html
              }
            }
            image {
              title
              description
              fluid(maxWidth: 1000) {
                ...GatsbyContentfulFluid
              }
            }
            thumbnail {
              title
              description
              fluid(maxWidth: 400) {
                ...GatsbyContentfulFluid
              }
            }
          }
        }
      }
    `}
    render={({
      contentfulHomePage: {
        expertsHeader: { expertsHeader: header },
        expertsSubHeader: { expertsSubHeader: subHeader },
        experts
      }
    }) => {
      const sortedExperts = experts.sort((a, b) => {
        return a.homePageDisplayOrder > b.homePageDisplayOrder;
      });
      return <Experts header={header} subHeader={subHeader} experts={sortedExperts} />
    }}
  />
);