import React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import { media } from "../utils/styles";
import CloseModal from "../components/Icons/CloseModal";

const StyledModal = styled.div`
  visibility: ${props => props.active ? 'visible' : 'hidden'};
  opacity: ${props => props.active ? '1' : '0'};
  transition: visibility 0s, opacity 500ms ease;
  width: 100%;
  height: 100%;
  background: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  z-index: 999;
  overflow-y: auto;
  padding: 25px;
  text-align: left;

  ${media.tablet`
    width: 689px;
    padding: 36px 41px;
    border-radius: 25px;
    box-shadow: 3px 3px 11px 0 #a1a1a1;
    overflow-y: visible;
    margin-left: 0;
    margin-right: 0;
    max-width: none;
    height: auto;
  `}

  ${media.desktop`
    height: 500px;
    max-width: 960px;
    margin: 0 auto;
    padding-left: 20x;
    padding-right: 20px;
    width: 760px;
    min-height: 558px;
  `}
`

const StyledCloseModal = styled(CloseModal)`
  width: 30px;
  height: 30px;
  padding: 0;
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
  z-index: 99;

  &:hover {
    .cls-1 { 
      fill: #00a0e1;
    }
  }

  ${media.tablet`
    width: 50px;
    height: 45px;
    right: -16px;
    top: -16px;
  `}
`

const ModalOverlay = styled.div`
  width: 100%;
  height: 100%;
  background: #00a0e1;
  position: fixed;
  left: 0;
  top: 0;
  display: ${props => props.active ? 'block' : 'none'};
  z-index: 998;
  opacity: .4;
`;

export default class Modal extends React.Component {
  constructor(props) {
    super(props);
    if (typeof document !== 'undefined') {
      this.el = document.createElement('div');
      this.modalRoot = document.getElementById('modal-root');
    }
  }

  canUseDOM() {
    return !!(typeof window !== 'undefined' && window.document && window.document.createElement);
  }

  componentDidMount() {
    this.modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    this.modalRoot.removeChild(this.el);
  }

  render() {
    const { active, children, onToggle, className } = this.props;
    const modal = (
      <React.Fragment>
        <StyledModal active={active} className={className}>
          <StyledCloseModal onClick={onToggle} />
          {children}
        </StyledModal>
        <ModalOverlay active={active} onClick={onToggle} />
      </React.Fragment>
    );
    if (this.canUseDOM()) {
      return ReactDOM.createPortal(
        modal,
        this.el,
      )
    } else {
      return null;
    }
  }
}