import React from 'react';
import styled from 'styled-components';
import { media, sizes } from '../../../utils/styles';
import Heading from '../../../elements/Heading';
import SubHeading from '../../../elements/SubHeading';
import { TransparentButton } from '../../../elements/Buttons';
import Expert from './Expert';
import Carousel from 'nuka-carousel';
import Marker from '../../../elements/Marker';
import Section from '../../../elements/Section';
import Wrapper from '../../../elements/Wrapper';
import {
  compose,
  lifecycle,
  withStateHandlers,
  onlyUpdateForKeys
} from 'recompose';

const StyledCarousel = styled(Carousel)`
  ul.slider-list {
    height: 400px !important;
  }
`;

const ArticlesButton = styled(TransparentButton)`
  margin-top: 10px;

  ${media.tablet`
    margin-top: 0;
  `}
`;

const Markers = styled.ul`
  text-align: center;
  margin-top: 20px;
  padding-left: 0;
`;

const ExpertListing = styled.ul`
  list-style: none;
  margin: 0 auto;
  padding: 0;

  ${media.tablet`
    display: table-row;
  `}
`;

const ExpertHolder = styled.li`
  text-align: center;
  padding: 0;
  width: 100%;
  margin: 0 auto;

  ${media.tablet`
    display: table-cell;
    float: left;
    padding: 0 10px;
    width: 33.333%;
  `}

  ${media.desktop`
    width: 25%;
  `}
`;

const ExpertWrapper = styled.div`
  margin-bottom: 40px;

  ${media.desktop`
    margin: 0 auto 50px;
    padding-left: 0;
    padding-right: 0;
    max-width: 1024px;
  `}
`;

const enhance = compose(
  onlyUpdateForKeys(['showCarousel', 'slideIndex']),
  withStateHandlers(
    () => ({
      slideIndex: 0,
      showCarousel:
        typeof window !== 'undefined' ? window.innerWidth < sizes.tablet : false
    }),
    {
      setSlide: props => index => ({
        slideIndex: index
      }),
      handleResize: props => event => ({
        showCarousel: window.innerWidth < sizes.tablet
      })
    }
  ),
  lifecycle({
    componentDidMount() {
      window.addEventListener('resize', this.props.handleResize);
    },
    componentWillUnmount() {
      window.removeEventListener('resize', this.props.handleResize);
    }
  })
);

export default enhance(
  ({ header, subHeader, showCarousel, slideIndex, setSlide, experts }) => (
    <Section id="meet-our-experts" backgroundColor={'#efefef'}>
      <Wrapper centerText={true}>
        <Heading>{header}</Heading>
        <SubHeading>{subHeader}</SubHeading>
        {showCarousel && (
          <>
            <StyledCarousel
              slideIndex={slideIndex}
              afterSlide={slideIndex => setSlide(slideIndex) }
              renderBottomCenterControls={({ currentSlide }) => {
                return null;
              }}
              renderCenterRightControls={({ currentSlide }) => {
                return null;
              }}
              renderCenterLeftControls={({ currentSlide }) => {
                return null;
              }}
            >
              {experts.map(expert => {
                let biography = '';
                if (expert.biography !== null) {
                  biography = expert.biography.childMarkdownRemark.html;
                }
                return (
                  <Expert
                    name={expert.name}
                    discipline={expert.discipline.name}
                    biography={biography}
                    image={expert.image}
                    thumbnail={expert.thumbnail}
                    key={`ExpertSlide_${expert.id}`}
                  />
                );
              })}
            </StyledCarousel>
            <Markers>
              {experts.map((expert, index) => (
                <Marker
                  key={`testimonialMarker_${index}`}
                  onClick={() => setSlide(index)}
                  active={slideIndex === index}
                />
              ))}
            </Markers>
          </>
        )}
        {!showCarousel && (
          <ExpertWrapper>
            <ExpertListing>
              {experts.map(expert => {
                let biography = '';
                if (expert.biography !== null) {
                  biography = expert.biography.childMarkdownRemark.html;
                }
                return (
                  <ExpertHolder key={`ExpertSlide_${expert.id}`}>
                    <Expert
                      name={expert.name}
                      discipline={expert.discipline.name}
                      biography={biography}
                      image={expert.image}
                      thumbnail={expert.thumbnail}
                    />
                  </ExpertHolder>
                );
              })}
            </ExpertListing>
          </ExpertWrapper>
        )}
        <ArticlesButton to="/articles" label="Read our Expert Articles">
          Read our expert articles
        </ArticlesButton>
      </Wrapper>
    </Section>
  )
);