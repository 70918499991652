import React from 'react';
import styled from 'styled-components';
import { media } from '../../../utils/styles';
import OpenModalIcon from '../../Icons/OpenModal';
import Modal from './Modal';
import Image from 'gatsby-image';
import { compose, withStateHandlers } from 'recompose';

const Card = styled.div`
  text-align: center;
  width: 80%;
  margin: 20px auto 20px auto;
  padding: 10px 10px 28px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 3px 3px 11px 0 #a1a1a1;
  position: relative;
  cursor: pointer;

  .cls-1 {
    transition: fill 0.1s linear;
  }

  &:hover {
    transition: background-color 0.1s 0.3s, color 0.1s 0.3s, border-color 0.1s 0.3s;
    .cls-1 { 
      fill: #00a0e1;
    }
  }
  
  ${media.tablet`
    width: auto;
  `}
`;

const OpenModal = styled(OpenModalIcon)`
  display: inline-block;
  position: absolute;
  top: 15px;
  right: -9px;
  z-index: 99;
  padding: 0;
`;

const ExpertName = styled.h4`
  font-family: ${props => props.theme.sansSerifFontFamily};
  font-size: 19px;
  line-height: 25px;
  font-weight: 400;
  letter-spacing: 0;
  color: ${props => props.theme.primary};
  margin: 20px 0 0;
`;

const Disclipline = styled.h5`
  font-family: ${props => props.theme.sansSerifFontFamily};
  font-size: 14.5px;
  line-height: 21.5px;
  font-weight: 300;
  letter-spacing: 0;
  color: #4d4d4d;
  margin: 0;
`;

const Thumbnail = styled(Image)`
  height: 250px;
`;

const enhance = compose(
  withStateHandlers(() => ({ openModal: false }), {
    onModalToggle: ({ openModal }) => () => ({
      openModal: !openModal
    }),
  })
);

export default enhance(({
  onModalToggle,
  openModal,
  name,
  discipline,
  biography,
  image,
  thumbnail
}) => <>
  <Card onClick={() => onModalToggle()}>
    <OpenModal />
    {image &&
      <Thumbnail title={image.title} alt={image.description} fluid={thumbnail.fluid} />}
    <ExpertName>{name}</ExpertName>
    <Disclipline>{discipline}</Disclipline>
  </Card>
  <Modal
    active={openModal}
    name={name}
    discipline={discipline}
    image={thumbnail}
    biography={biography}
    onToggle={() => onModalToggle()}
  />
</>);
