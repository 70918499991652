import React from 'react';
import styled from 'styled-components';
import Testimonial from './Testimonial';
import Carousel from 'nuka-carousel';
import Marker from '../../../elements/Marker';
import Section from '../../../elements/Section';
import Wrapper from '../../../elements/Wrapper';
import {
  compose,
  withStateHandlers,
  onlyUpdateForKeys
} from 'recompose';

const Markers = styled.ul`
  text-align: center;
  margin: 40px 0 0;
  padding: 0;
`;

const enhance = compose(
  onlyUpdateForKeys(['slideIndex']),
  withStateHandlers(
    () => ({
      slideIndex: 0,
    }),
    {
      setSlide: props => index => ({
        slideIndex: index
      }),
    }
));

export default enhance(({ slideIndex, setSlide, testimonials }) => {
  const filteredTestimonials = testimonials.filter(({node}) => !!node.testimonial && !!node.avatar.fixed.width);
  return (
    <Section id="testimonials" backgroundColor={'#efefef'}>
      <Wrapper>
        <Carousel
          slideIndex={slideIndex}
          afterSlide={slideIndex => setSlide(slideIndex)}
          renderBottomCenterControls={({ currentSlide }) => {
            return null;
          }}
          renderCenterRightControls={({ currentSlide }) => {
            return null;
          }}
          renderCenterLeftControls={({ currentSlide }) => {
            return null;
          }}
          initialSlideHeight={400}
        >
          {filteredTestimonials.map(({ node: testimonial }) => (
            <Testimonial
              key={`TestimonialSlide_${testimonial.id}`}
              name={testimonial.name}
              avatar={testimonial.avatar}
              testimonial={testimonial.testimonial.testimonial}
            />
          ))}
        </Carousel>
        <Markers>
          {filteredTestimonials.map((node, index) => (
            <Marker
              key={`testimonialMarker_${index}`}
              onClick={() => setSlide(index)}
              active={slideIndex === index}
            />
          ))}
        </Markers>
      </Wrapper>
    </Section>
  )
});
