import React from 'react';
import styled from 'styled-components';

const StyledIcon = styled.svg`
  .copy { font-size: 24px; fill:#4d4d4d; font-family: Marydale-Bold, Marydale; font-weight:700; }
  .line { fill: none; stroke:#4d4d4d; stroke-linecap: round; stroke-linejoin :round; stroke-width:2px; }
`;

const Wrapper = styled.span``;

export default ({ onClick, className }) => (
  <Wrapper onClick={onClick}>
    <StyledIcon
      viewBox="0 0 261.18 108.4"
      className={className}
    >
      <text className="copy" transform="translate(3.69 70.98)">
        <tspan>2 week unlimited</tspan>
        <tspan x="35.62" y="24">free trial!</tspan>
      </text>
      <path className="line" d="M259.34,13.51S190.68-16,135.54,31.24" />
      <polyline className="line" points="254.91 1 260.18 14.14 247.04 19.4" />
    </StyledIcon>
  </Wrapper>
);
