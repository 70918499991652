import React from "react";
import styled from "styled-components";

const StyledIcon = styled.svg`
  .cls-1{isolation: isolate;}.cls-2{fill: #fde02b;}.cls-3,.cls-5,.cls-6,.cls-7,.cls-8{fill: #fff;}.cls-10,.cls-4{fill: #f5f5f5;}.cls-4,.cls-9{mix-blend-mode: multiply;}.cls-5,.cls-6,.cls-7,.cls-8{stroke: #d9d9d9;}.cls-5{stroke-miterlimit: 10;}.cls-5,.cls-7,.cls-8{stroke-width: 1.5px;}.cls-6,.cls-8{stroke-linecap: round;}.cls-6,.cls-7,.cls-8{stroke-linejoin: round;}.cls-6{stroke-width: 1.07px;}.cls-11{fill: #feec80;}
`;

const Wrapper = styled.span`
  display: block;
`;

export default ({ onClick }) => (
  <Wrapper onClick={onClick}>
    <StyledIcon
      viewBox="0 0 182.51 202.24"
      height="190"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g className="cls-1">
        <g data-name="Layer 2">
          <circle className="cls-2" cx="48.28" cy="48.28" r="48.28" />
          <path className="cls-3" d="M11.45,53.69V44.45a1,1,0,0,1,1.07-1.05h6a.88.88,0,1,1,0,1.75H13.46v2.94h4.93a.88.88,0,1,1,0,1.75H13.46V53h5.05a.88.88,0,1,1,0,1.75h-6A1,1,0,0,1,11.45,53.69Z" />
          <path className="cls-3" d="M31.44,53.87a1,1,0,0,1-1,1,.93.93,0,0,1-.75-.31l-3.55-4.16-3.58,4.16a.93.93,0,0,1-.75.31,1,1,0,0,1-1-1,1,1,0,0,1,.24-.61l3.79-4.33-3.55-4a1,1,0,0,1-.22-.6,1,1,0,0,1,.94-1,.9.9,0,0,1,.75.34l3.34,3.87,3.31-3.87a.9.9,0,0,1,.75-.34,1,1,0,0,1,.95,1,1,1,0,0,1-.22.6l-3.56,4,3.8,4.33A1.11,1.11,0,0,1,31.44,53.87Z" />
          <path className="cls-3" d="M33.39,53.84V44.45a1,1,0,0,1,1.07-1.05h4a3.56,3.56,0,1,1,0,7.11h-3v3.33a1,1,0,0,1-1,1A1,1,0,0,1,33.39,53.84Zm4.76-8.69H35.4v3.61h2.75a1.81,1.81,0,1,0,0-3.61Z" />
          <path className="cls-3" d="M44.18,53.69V44.3a1,1,0,0,1,2,0V53h4.25a.88.88,0,1,1,0,1.75h-5.2A1,1,0,0,1,44.18,53.69Z" />
          <path className="cls-3" d="M58.22,43.21a5.87,5.87,0,1,1-5.85,5.87A5.66,5.66,0,0,1,58.22,43.21Zm0,1.77a3.77,3.77,0,0,0-3.79,4.1,3.81,3.81,0,1,0,7.59,0A3.79,3.79,0,0,0,58.22,45Z" />
          <path className="cls-3" d="M75.18,53.94a1,1,0,0,1-.94.9,1.08,1.08,0,0,1-.84-.42l-2.7-3.91H68.37v3.33a1,1,0,0,1-2,0V44.45a1,1,0,0,1,1.07-1.05h4A3.45,3.45,0,0,1,75.13,47a3.18,3.18,0,0,1-2.37,3.27L75,53.31A1,1,0,0,1,75.18,53.94Zm-6.81-5.18h2.75a1.81,1.81,0,1,0,0-3.61H68.37Z" />
          <path className="cls-3" d="M77.49,53.69V44.45a1,1,0,0,1,1.06-1.05h6a.88.88,0,1,1,0,1.75h-5v2.94h4.92a.88.88,0,1,1,0,1.75H79.5V53h5a.88.88,0,1,1,0,1.75h-6A1,1,0,0,1,77.49,53.69Z" />
          <path className="cls-3" d="M110,142.8H60.29A14.31,14.31,0,0,1,46,128.5V80.55A14.31,14.31,0,0,1,60.29,66.26h55.14a14.31,14.31,0,0,1,14.29,14.29v76Z" />
          <rect className="cls-4" x="82.31" y="85.09" width="80.5" height="105.99" rx="7.07" ry="7.07" />
          <rect className="cls-5" x="85.55" y="81.71" width="80.5" height="105.99" rx="7.07" ry="7.07" />
          <rect className="cls-5" x="94.01" y="90.35" width="63.59" height="74.68" />
          <path className="cls-5" d="m127.68 177a3 3 0 1 1 -3 -3 3 3 0 0 1 3 3z" />
          <path className="cls-4" d="M179.66,148.78a4.84,4.84,0,0,0-4.84-4.84h-.28a4.83,4.83,0,0,0-4.82,4.61,4.83,4.83,0,0,0-4.82-4.61h-.29a4.83,4.83,0,0,0-4.81,4.61,4.84,4.84,0,0,0-4.82-4.61h-.29a4.82,4.82,0,0,0-4.79,4.39V126.68a4.84,4.84,0,0,0-4.85-4.84h-.28a4.83,4.83,0,0,0-4.84,4.84v47.59c-8-19.86-12.06-17.68-13-17.34s-3.51,2.09-1.55,6c3.52,6.93,4.93,20.07,8.55,26.7a24.33,24.33,0,0,0,21.34,12.65h0A24.31,24.31,0,0,0,179.62,178Z" />
          <path className="cls-6" d="M181.76,145.4a4.84,4.84,0,0,0-4.84-4.84h-.29a4.84,4.84,0,0,0-4.82,4.61,4.82,4.82,0,0,0-4.81-4.61h-.29a4.82,4.82,0,0,0-4.82,4.61,4.82,4.82,0,0,0-4.82-4.61h-.28a4.83,4.83,0,0,0-4.8,4.38V123.29a4.84,4.84,0,0,0-4.84-4.84h-.29a4.84,4.84,0,0,0-4.84,4.84v47.6c-8-19.86-12.06-17.68-13-17.34s-3.51,2.08-1.54,5.95c3.52,6.93,4.92,20.08,8.55,26.71a24.3,24.3,0,0,0,21.33,12.65h0a24.31,24.31,0,0,0,24.31-24.25Z" />
          <path className="cls-7" d="M181.76,145.4a4.84,4.84,0,0,0-4.84-4.84h-.29a4.84,4.84,0,0,0-4.82,4.61,4.82,4.82,0,0,0-4.81-4.61h-.29a4.82,4.82,0,0,0-4.82,4.61,4.82,4.82,0,0,0-4.82-4.61h-.28a4.83,4.83,0,0,0-4.8,4.38V123.29a4.84,4.84,0,0,0-4.84-4.84h-.29a4.84,4.84,0,0,0-4.84,4.84v47.6c-8-19.86-12.06-17.68-13-17.34s-3.51,2.08-1.54,5.95c3.52,6.93,4.92,20.08,8.55,26.71a24.3,24.3,0,0,0,21.33,12.65h0a24.31,24.31,0,0,0,24.31-24.25Z" />
          <line className="cls-8" x1="171.86" x2="171.86" y1="144.14" y2="155.49" />
          <line className="cls-8" x1="161.85" x2="161.85" y1="144.14" y2="155.49" />
          <line className="cls-8" x1="152" x2="152" y1="144.14" y2="155.49" />
          <g className="cls-9">
            <path className="cls-10" d="M120.72,147.73l-11.87-8.26H57.29a8.3,8.3,0,0,1-8.29-8.3V83.23a8.3,8.3,0,0,1,8.29-8.3h55.14a8.3,8.3,0,0,1,8.29,8.3Z" />
          </g>
          <path className="cls-5" d="M123.72,145.06l-11.87-8.26H60.29A8.3,8.3,0,0,1,52,128.5V80.55a8.29,8.29,0,0,1,8.29-8.29h55.14a8.29,8.29,0,0,1,8.29,8.29Z" />
          <path className="cls-11" d="M112.37,131h-51a3.29,3.29,0,0,1-3.29-3.29v-46a3.29,3.29,0,0,1,3.29-3.28h53a3.28,3.28,0,0,1,3.28,3.28v53Z" />
          <rect className="cls-3" transform="translate(149.37 179.49) rotate(180)" x="67.17" y="87.89" width="15.04" height="3.7" />
          <rect className="cls-3" transform="translate(181.64 195.74) rotate(180)" x="67.17" y="96.04" width="47.3" height="3.67" />
          <rect className="cls-3" transform="translate(181.64 214.99) rotate(180)" x="67.17" y="105.66" width="47.3" height="3.67" />
          <rect className="cls-3" transform="translate(173.16 234.23) rotate(180)" x="62.93" y="115.28" width="47.3" height="3.67" />
          <path className="cls-2" d="M114.34,78.38H61.39a3.28,3.28,0,0,0-3,1.87,3.23,3.23,0,0,1,1.42-.34h53a3.29,3.29,0,0,1,3.29,3.28v50.4l1.52,1.06v-53A3.29,3.29,0,0,0,114.34,78.38Z" />
        </g>
      </g>
    </StyledIcon>
  </Wrapper>
)

