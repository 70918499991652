import React from 'react';
import styled from 'styled-components';
import { media } from '../../../utils/styles';
import Form from './Form';
import CoverImage from './CoverImage';
import Wrapper from '../../../elements/Wrapper';
import {
  withStateHandlers,
  compose,
  onlyUpdateForKeys,
  lifecycle,
} from 'recompose';

const Section = styled.section`
  padding: 0;
  background-color: #f3f3f3;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;

  &:before {
    position: absolute;
    content: '';
    background-image: ${props => props.showBackground ? `url("${props.backgroundSrc}")` : 'none'};
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: ${props => props.showBackground ? `1` : `0`};
    transition: opacity 0.5s ease;
    background-repeat: no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  ${media.desktop`
    padding: 120px 0 60px 0;
  `};
`;

const Content = styled.div`
  margin-top: -190px;
  z-index: 2;

  ${media.tablet`
    margin-top: -120px;
  `}

  ${media.desktop`
    margin: 0 auto;
  `}

  ${media.giant`
  `}
`;

const Title = styled.h1`
  font-family: ${props => props.theme.headerFontFamily};
  font-size: 38px;
  line-height: 40px;
  font-weight: 400;
  letter-spacing: 0.065em;
  color: ${props => props.theme.primary};
  text-align: center;
  padding: 0 20px;
  text-transform: uppercase;
  margin: 0;
  width: 100%;
  position: relative;
  z-index: 1;

  ${media.tablet`
    font-size: 45px;
    line-height: 50px;
    width: 100%;
    text-align: center;
    padding-right: 54px;
    padding-left: 54px;
  `} ${media.desktop`
    font-size: 62px;
    line-height: 72px;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    width: 800px;
  `};
`;

const SubHeading = styled.p`
  font-family: ${props => props.theme.headerFontFamily};
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.065em;
  color: #4d4d4d;
  text-align: center;
  padding: 0;
  margin: 20px 0 0;
  position: relative;
  z-index: 1;

  ${media.desktop`
    text-align: left;
    font-size: 27px;
    line-height: 40px;
    width: 800px;
    margin-top: 60px;
  `};
`;

const Description = styled.p`
  font-family: ${props => props.theme.headerFontFamily};
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0;
  font-weight: 400;
  color: #4d4d4d;
  margin: 20px 0 34px;
  padding-right: 25px;
  padding-left: 25px;
  text-align: center;
  position: relative;
  z-index: 1;

  ${media.tablet`
    padding-right: 54px;
    padding-left: 54px;
    margin: 20px 0 31px;
    text-align: center;
    margin-top:0;
  `}
  
  ${media.desktop`
    margin-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
    text-align: left;
    font-size: 27px;
    line-height: 40px;
    width: 800px;
  `};
`;

const enhance = compose(
  onlyUpdateForKeys(['src', 'showBackground']),
  withStateHandlers(
    () => ({
      src:
        'https://images.ctfassets.net/3nvu4b9mcmye/5XtqSIyyKAWWcmKuo8mQU/5298969aea1f9ce7d9e090396ea993b1/header-background-desktop2.jpg?w=2000&h=1334&q=50',
      showBackground: false,
    }),
    {
      setBackground: props => src => ({
        src,
        showBackground: true
      }),
      removeBackground: props => () => ({
        src: '',
        showBackground: false
      }),
      handleResize: props => () => ({
        showBackground: window.innerWidth >= 1024 ? true : false,
      })
    }
  ),
  lifecycle({
    componentDidMount() {
      window.addEventListener('resize', this.props.handleResize);
      this.props.handleResize();
    },
    componentWillUnmount() {
      window.removeEventListener('resize', this.props.handleResize);
    }
  })
);

export default enhance(
  ({
    showBackground,
    src,
    setBackground,
    removeBackground,
    tagLine,
    introSubHeading,
    description,
  }) => {
    return (
    <Section id="home" showBackground={showBackground} backgroundSrc={src}>
      <CoverImage
        setBackground={setBackground}
        removeBackground={removeBackground}
      />
      <Wrapper>
        <Content>
          <Title>{tagLine}</Title>
          <SubHeading>{introSubHeading}</SubHeading>
          <Description>{description}</Description>
          <Form />
        </Content>
      </Wrapper>
    </Section>
  )}
);