import React from 'react';
import styled from 'styled-components';
import { media } from '../../../utils/styles';
import TrackIcon from '../../Icons/Track';
import BoostIcon from '../../Icons/Boost';
import ExploreIcon from '../../Icons/Explore';
import CaptureIcon from '../../Icons/Capture';

const Feature = styled.li`
  text-align: center;
  padding: 0;
  width: 100%;
  margin: 40px auto 0;

  ${media.tablet`
    display: table-cell;
    float: left;
    width: 50%;
  `}
`;

const FeatureTitle = styled.h4`
  font-family: ${props => props.theme.headerFontFamily};
  font-size: 23px;
  line-height: 27px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .02em;
  color: ${props => props.theme.primary};
  width: 290px;
  margin: 20px auto 16px;

  ${media.tablet`
    width: 300px;
  `}

  ${media.desktop`
    font-size: 24px;
    line-height: 28px;
    width: 336px;
  `}
`;

const FeatureDescription = styled.p`
  font-family: ${props => props.theme.sansSerifFontFamily};
  font-size: 17px;
  line-height: 25px;
  font-weight: 300;
  letter-spacing: 0;
  color: #4d4d4d;
  width: 254px;
  margin: 0 auto;

  ${media.tablet`
    width: 310px;
  `} ${media.desktop`
    font-size: 18px;
    line-height: 28px;
    width: 410px;
  `};
`;

export default ({ header, description, displayOrder }) => (
  <Feature>
    {
      (() => {
        switch (displayOrder) {
          case 1: return <TrackIcon />;
          case 2: return <BoostIcon />;
          case 3: return <ExploreIcon />;
          case 4: return <CaptureIcon />;
          default: return <TrackIcon />;
        }
      })()
    }
    <FeatureTitle>{header}</FeatureTitle>
    <FeatureDescription>{description.description}</FeatureDescription>
  </Feature>
)