import React from 'react';
import styled from 'styled-components';
import { media } from '../../../utils/styles';
import Header from '../../../elements/Heading';
import SubHeader from '../../../elements/SubHeading';
import Button from '../../../elements/Buttons';
import AccessAppArrow from '../../Icons/AccessAppArrow';
import Section from '../../../elements/Section';
import Wrapper from '../../../elements/Wrapper';
import { StaticQuery, graphql } from 'gatsby';

const StyledHeader = styled(Header)`
  color: ${props => props.theme.primary};
  margin: 0 auto 20px;
  padding-top: 46px;

  ${media.tablet`
    text-align: left;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 41px;
  `}

  ${media.desktop`
    font-size: 40px;
    line-height: 80px;
    padding-top: 10px;
    margin-bottom: 23px;
    width: auto;
  `}
`;

const StyledSubHeader = styled(SubHeader)`
  margin: 0 0 23px;
  padding: 0;
  width: auto;

  ${media.tablet`
    width: auto;
  `}

  ${media.desktop`
    width: auto;
  `}
`;

const Image = styled.picture`
  > img {
    width: 79%;
  }

  ${media.tablet`
    > img {
      width: 50%;
      float: left;
      padding-left: 20px;
    }
  `}

  ${media.desktop`
    > img {
      width: 405px;
    }
  `}
`;

const Content = styled.div`
  text-align: center;

  ${media.tablet`
    text-align: left;
    width: 50%;
    display: inline-block;
    float: left;
    padding-left: 25px;
  `}

  ${media.desktop`
    width: 500px;
    padding-left: 50px;
    position: relative;
  `}
`;

const AvailableCopy = styled.p`
  font-family: ${props => props.theme.sansSerifFontFamily};
  font-size: 14px;
  line-height: 20px;
  font-weight: 300;
  letter-spacing: 0;
  color: #4d4d4d;
  font-style: italic;
  margin-bottom: 43px;

  ${media.tablet`
    font-size: 15px;
    line-height: 15px;
  `}

  ${media.desktop`
    margin-bottom: 35px;
  `}
`;

const StyledButton = styled(Button)`
  display: block;
  margin: 0 20px;
`;

const AccessArrow = styled(AccessAppArrow)`
  width: 225px;
  position: relative;
  margin-top: 30px;
  margin-bottom: -30px;

  ${media.tablet`
    margin-left: 100px;
    margin-top: 30px;
    padding-bottom: 40px;
  `}

  ${media.desktop`
    margin-left: 200px;
  `}
`;

export default () =>
  <StaticQuery
    query={graphql`
      query {
        contentfulHomePage {
          theAppHeader
          theAppSubHeader {
            theAppSubHeader
          }
          theAppAvailableOn {
            theAppAvailableOn
          }
          theAppImage {
            title
            description
            fluid(maxWidth: 660) {
              ...GatsbyContentfulFluid
            }
          }
          theAppImageLarge {
            title
            description
            fluid(maxWidth: 1677) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    `}
    render={({
      contentfulHomePage: {
        theAppHeader: header,
        theAppSubHeader: { theAppSubHeader: subHeader },
        theAppAvailableOn: { theAppAvailableOn: availableOn },
        theAppImage: image,
        theAppImageLarge: imageLarge
      }
    }) => (
      <Section id="take-us-with-you" backgroundColor={'#efefef'}>
        <Wrapper centerText={true}>
          <Image>
            <source
              srcSet={image.fluid.srcSet}
              media="(max-width: 768px)"
            />
            <img
              srcSet={imageLarge.fluid.srcSet}
              alt={imageLarge.description}
              title={imageLarge.title}
              src={imageLarge.src}
            />
          </Image>
          <Content>
            <StyledHeader>{header}</StyledHeader>
            <StyledSubHeader>
              {subHeader}
            </StyledSubHeader>
            <AvailableCopy>{availableOn}</AvailableCopy>
            <StyledButton href={process.env.GATSBY_SIGNUP_URL}>Sign up for a Free Trial</StyledButton>
            <AccessArrow />
          </Content>
        </Wrapper>
      </Section>
    )}
  />
