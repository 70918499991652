import React from 'react';
import styled from 'styled-components';
import { media } from '../../../utils/styles';
import Heading from '../../../elements/Heading';
import SubHeading from '../../../elements/SubHeading';
import Feature from './Feature';
import Section from '../../../elements/Section';
import Wrapper from '../../../elements/Wrapper';
import { StaticQuery, graphql } from 'gatsby';

const Features = styled.ul`
  list-style: none;
  margin: 0 auto;
  padding: 0;

  ${media.tablet`
    display: table-row;
  `}
`;

export default () => (
  <StaticQuery
    query={graphql`
      query {
        contentfulHomePage {
          featuresHeader
          featuresSubHeader {
            featuresSubHeader
          }
        }
        allContentfulHomePageFeature(
          sort: { fields: [displayOrder] }
          filter: { node_locale: { eq: "en" } }
        ) {
          edges {
            node {
              id
              displayOrder
              header
              description {
                description
              }
            }
          }
        }
      }
    `}
    render={data => {
      const {
        contentfulHomePage: {
          featuresHeader: header ,
          featuresSubHeader: { featuresSubHeader: subHeader }
        },
        allContentfulHomePageFeature: { edges: features }
      } = data;
      return (
        <Section id="expert-advice-you-can-trust">
          <Wrapper>
            <Heading>{header}</Heading>
            <SubHeading>{subHeader}</SubHeading>
            <Features>
              {features.map(({ node: feature }) => (
                <Feature
                  key={`feature_${feature.id}`}
                  header={feature.header}
                  description={feature.description}
                  displayOrder={feature.displayOrder}
                />
              ))}
            </Features>
          </Wrapper>
        </Section>
      );
    }}
  />
);