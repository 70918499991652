import React from "react";
import styled from "styled-components";
import { media } from "../../../utils/styles";
import Sticker from "../../Icons/DealSticker";

const Card = styled.li`
  background: #fff;
  margin-bottom: 20px;
  display: inline-block;
  text-align: center;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;

  ${media.tablet`
    width: 450px;
  `}
`;

const Heading = styled.div`
  padding: 19px 15px 17px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  position: relative;
  background: ${props => props.feature ? '#88ceed' : '#00a0e1'};
  z-index: 5;
`;

const Title = styled.h4`
  font-family: 'proxima-soft',Helvetica,Arial,sans-serif;
  font-size: 23px;
  line-height: 42px;
  font-weight: 700;
  letter-spacing: .105em;
  color: #4d4d4d;
  text-transform: uppercase;
  margin: 0;

  ${media.tablet`
    font-size: 22px;
    line-height: 35px;
  `}

  ${media.desktop`
    font-size: 24px;
    line-height: 42px;
  `}
`;

const Cost = styled.span`
  font-family: 'proxima-soft',Helvetica,Arial,sans-serif;
  font-size: 48px;
  line-height: 46px;
  font-weight: 700;
  letter-spacing: .02em;
  color: ${props => props.feature ? '#88ceed' : '#00a0e1'};
  display: block;

  ${media.tablet`
    font-size: 40px;
    line-height: 38.5px;
  `}

  ${media.desktop`
    font-size: 48px;
    line-height: 46px;
  `}
`

const Content = styled.div`
  padding: 28px 12px;
  border: 2px solid #EDF0F9;
  border-top: none;
  background: #fff;

  &::before {
    content: '';
    width: 0;
    height: 0;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    border-top: ${props => props.feature ? '25px solid #88d0eb' : '25px solid #00a0e1'};
    font-size: 0;
    line-height: 0;
    display: block;
    margin: 0 auto;
    margin-top: -28px;
    margin-bottom: 5px;
  }
`;

const List = styled.ul`
  text-align: center;
  margin: 20px 0 0 0;
  list-style-type: none;
  padding: 0;
`;

const ListItem = styled.li`
  font-family: 'bree-serif', Helvetica, Arial, sans-serif;
  font-size: 17px;
  line-height: 26px;
  font-weight: 300;
  letter-spacing: 0;
  color: #333;

  ${media.tablet`
    font-size: 14px;
    line-height: 17px;
  `}

  ${media.desktop`
    font-size: 17px;
    line-height: 26px;
  `}
`;

const StyledSticker = styled(Sticker)`
  width: 75px;
  height: 75px;
  position: absolute;
  z-index: 100;
  margin-left: 70px;
  margin-top: 30px;

  ${media.tablet`
    margin-top: 120px;
    margin-left: 110px;
    width: 110px;
    height: 110px;
  `}

  ${media.desktop`
    width: 125px;
    height: 125px;
    margin-top: -70px;
    margin-left: 90px;
  `}
`;

export default ({ header, amount, pricingFeatures, feature }) => (
  <Card>
    {feature && <StyledSticker />}
    <Heading feature={feature}>
      <Title>{header}</Title>
    </Heading>
    <Content feature={feature}>
      <Cost feature={feature}>{amount}</Cost>
      <List>
        {
          pricingFeatures.map((feature) => (
            <ListItem key={feature}>{feature}</ListItem>
          ))
        }
      </List>
    </Content>
  </Card>
)