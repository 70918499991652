import React from "react";
import styled from "styled-components";

const StyledIcon = styled.svg`
`;

const Wrapper = styled.span`
  display: block;
`;

export default ({ onClick }) => (
  <Wrapper onClick={onClick}>
    <StyledIcon
      viewBox="0 0 215 189"
      height="189"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <polygon id="path-1" points="0 0.0918167539 96.0481832 0.0918167539 96.0481832 96.1393037 0 96.1393037"></polygon>
        <polygon id="path-3" points="0 189.908482 214.714921 189.908482 214.714921 0.994764398 0 0.994764398"></polygon>
      </defs>
      <g fill="none" fillRule="evenodd" strokeWidth="1">
        <g transform="translate(0 -1)">
          <g id="Group-3" transform="translate(0.000000, 0.903246)">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#path-1"></use>
            </mask>
            <g id="Clip-2"></g>
            <path d="M96.0481832,48.1160576 C96.0481832,74.638466 74.5473455,96.1393037 48.0239424,96.1393037 C21.501534,96.1393037 -0.000298429319,74.638466 -0.000298429319,48.1160576 C-0.000298429319,21.5926545 21.501534,0.0918167539 48.0239424,0.0918167539 C74.5473455,0.0918167539 96.0481832,21.5926545 96.0481832,48.1160576" id="Fill-1" fill="#ED3154" mask="url(#mask-2)"></path>
          </g>
          <path d="M82.0282723,145.197602 C78.3118325,145.197602 75.0112042,142.697759 74.0045026,139.118597 L57.0139267,78.7493298 C56.4101047,76.6066073 56.6786911,74.3554555 57.7669634,72.4136754 C58.8572251,70.4709005 60.6368586,69.0692775 62.7805759,68.4654555 L112.116911,54.5795393 C112.854031,54.3726283 113.615026,54.268178 114.37801,54.268178 C118.09445,54.268178 121.394084,56.7670262 122.40178,60.3471832 L139.392356,120.71645 C139.995183,122.859173 139.727592,125.110325 138.638325,127.052105 C137.549058,128.99488 135.768429,130.396503 133.624712,131.000325 L84.288377,144.886241 C83.5512565,145.093152 82.7912565,145.197602 82.0282723,145.197602" id="Fill-4" fill="#FFFFFF"></path>
          <path d="M9.63290052,49.8168063 C9.63290052,46.3500524 12.2451518,43.9835079 15.5238953,43.9835079 C17.3512775,43.9835079 18.6146283,44.6758639 19.5188691,45.6567016 C19.6740524,45.8089005 19.7596021,46.0456545 19.7596021,46.2824084 C19.7596021,46.7897382 19.3318534,47.2125131 18.8195497,47.2125131 C18.5290785,47.2125131 18.2734241,47.0941361 18.1023246,46.8912042 C17.4875602,46.1978534 16.5823246,45.7412565 15.5238953,45.7412565 C13.3553089,45.7412565 11.6821152,47.449267 11.6821152,49.8168063 C11.6821152,52.1843455 13.3553089,53.892356 15.5238953,53.892356 C16.5823246,53.892356 17.4875602,53.4516754 18.1192356,52.7414136 C18.2903351,52.5563874 18.5290785,52.4201047 18.8195497,52.4201047 C19.3318534,52.4201047 19.7596021,52.8428796 19.7596021,53.3512042 C19.7596021,53.5869634 19.6740524,53.8237173 19.5188691,53.976911 C18.6146283,54.9567539 17.3681885,55.6501047 15.5238953,55.6501047 C12.2451518,55.6501047 9.63290052,53.2825654 9.63290052,49.8168063" id="Fill-6" fill="#FFFFFF"></path>
          <path d="M28.5042775,51.5411309 L26.4043298,45.8421257 L24.3033874,51.5411309 L28.5042775,51.5411309 Z M31.6974712,54.534377 C31.6974712,55.091445 31.2368953,55.5480419 30.6738586,55.5480419 C30.2461099,55.5480419 29.8710838,55.2784607 29.7338063,54.9064188 L29.1369476,53.2829634 L23.6727068,53.2829634 L23.0748534,54.9064188 C22.9375759,55.2784607 22.561555,55.5480419 22.1357958,55.5480419 C21.5717644,55.5480419 21.1101937,55.091445 21.1101937,54.534377 C21.1101937,54.416 21.1619215,54.280712 21.1957435,54.1792461 L24.7649581,45.1825969 C25.0206126,44.5409738 25.6532827,44.067466 26.4043298,44.067466 C27.1563717,44.067466 27.7870524,44.5409738 28.0437016,45.1825969 L31.6129162,54.1792461 C31.6467382,54.280712 31.6974712,54.416 31.6974712,54.534377 Z" id="Fill-8" fill="#FFFFFF"></path>
          <path d="M38.4451571,45.9106649 L35.713534,45.9106649 L35.713534,49.4957958 L38.4451571,49.4957958 C39.5722251,49.4957958 40.391911,48.785534 40.391911,47.7032304 C40.391911,46.6209267 39.5722251,45.9106649 38.4451571,45.9106649 M33.7150524,54.5511885 L33.7150524,45.2173141 C33.7150524,44.5408743 34.0910733,44.1688325 34.7744764,44.1688325 L38.7177225,44.1688325 C41.1091361,44.1688325 42.4411257,45.792288 42.4411257,47.7032304 C42.4411257,49.6141728 41.0922251,51.2366335 38.7177225,51.2366335 L35.713534,51.2366335 L35.713534,54.5511885 C35.713534,55.0913455 35.2698691,55.5479424 34.7227487,55.5479424 C34.1587173,55.5479424 33.7150524,55.0913455 33.7150524,54.5511885" id="Fill-10" fill="#FFFFFF"></path>
          <path d="M47.1571047,54.5506911 L47.1571047,45.9101675 L44.4931257,45.9101675 C44.0156387,45.9101675 43.6057958,45.5222094 43.6057958,45.0477068 C43.6057958,44.557288 44.0156387,44.1683351 44.4931257,44.1683351 L51.8195654,44.1683351 C52.2970524,44.1683351 52.7068953,44.557288 52.7068953,45.0477068 C52.7068953,45.5222094 52.2970524,45.9101675 51.8195654,45.9101675 L49.1555864,45.9101675 L49.1555864,54.5506911 C49.1555864,55.0918429 48.7109267,55.5484398 48.164801,55.5484398 C47.6007696,55.5484398 47.1571047,55.0918429 47.1571047,54.5506911" id="Fill-12" fill="#FFFFFF"></path>
          <path d="M54.6392251,45.0655131 C54.6392251,44.5243613 55.0828901,44.0677644 55.6469215,44.0677644 C56.2099581,44.0677644 56.653623,44.5243613 56.653623,45.0655131 L56.653623,50.9326335 C56.653623,52.725199 57.6613194,53.8920576 59.5742513,53.8920576 C61.4692775,53.8920576 62.4769738,52.725199 62.4769738,50.9326335 L62.4769738,45.0655131 C62.4769738,44.5243613 62.9206387,44.0677644 63.4856649,44.0677644 C64.0487016,44.0677644 64.4923665,44.5243613 64.4923665,45.0655131 L64.4923665,50.9833665 C64.4923665,53.7905916 62.8699058,55.650801 59.5742513,55.650801 C56.2616859,55.650801 54.6392251,53.7736806 54.6392251,51.0002775 L54.6392251,45.0655131 Z" id="Fill-14" fill="#FFFFFF"></path>
          <path d="M69.2584817,49.4952984 L71.9901047,49.4952984 C73.1171728,49.4952984 73.9368586,48.7850366 73.9368586,47.702733 C73.9368586,46.6204293 73.1171728,45.9101675 71.9901047,45.9101675 L69.2584817,45.9101675 L69.2584817,49.4952984 Z M76.0368063,54.6531518 C76.0368063,55.0411099 75.6617801,55.5484398 75.0977487,55.5484398 C74.7734555,55.5484398 74.4491623,55.3793298 74.2611518,55.1256649 L71.5802618,51.2371309 L69.2584817,51.2371309 L69.2584817,54.5506911 C69.2584817,55.0918429 68.8148168,55.5484398 68.2676963,55.5484398 C67.7046597,55.5484398 67.26,55.0918429 67.26,54.5506911 L67.26,45.2168168 C67.26,44.5413717 67.6360209,44.1693298 68.3194241,44.1693298 L72.2636649,44.1693298 C74.517801,44.1693298 75.9860733,45.6226806 75.9860733,47.702733 C75.9860733,49.5460314 74.8759162,50.6114241 73.6304712,50.949644 L75.8497906,54.027445 C75.9522513,54.179644 76.0368063,54.3308482 76.0368063,54.6531518 Z" id="Fill-16" fill="#FFFFFF"></path>
          <path d="M78.3279476,54.3991885 L78.3279476,45.2175131 C78.3279476,44.5410733 78.7039686,44.1690314 79.386377,44.1690314 L85.3470052,44.1690314 C85.8244921,44.1690314 86.2343351,44.5569895 86.2343351,45.0484031 C86.2343351,45.521911 85.8244921,45.9108639 85.3470052,45.9108639 L80.3264293,45.9108639 L80.3264293,48.8354712 L85.2276335,48.8354712 C85.7051204,48.8354712 86.1149634,49.2254188 86.1149634,49.6979319 C86.1149634,50.1893455 85.7051204,50.5773037 85.2276335,50.5773037 L80.3264293,50.5773037 L80.3264293,53.7058377 L85.3470052,53.7058377 C85.8244921,53.7058377 86.2343351,54.0937958 86.2343351,54.5682984 C86.2343351,55.0587173 85.8244921,55.4476702 85.3470052,55.4476702 L79.386377,55.4476702 C78.7039686,55.4476702 78.3279476,55.0756283 78.3279476,54.3991885" id="Fill-18" fill="#FFFFFF"></path>
          <mask id="mask-4" fill="white">
            <use xlinkHref="#path-3"></use>
          </mask>
          <g id="Clip-21"></g>
          <polygon id="Fill-20" fill="#FFFFFF" mask="url(#mask-4)" points="81.7368063 91.6655497 96.6940838 91.6655497 96.6940838 87.9829319 81.7368063 87.9829319"></polygon>
          <polygon id="Fill-22" fill="#FFFFFF" mask="url(#mask-4)" points="81.7368063 99.7301047 128.791152 99.7301047 128.791152 96.0842932 81.7368063 96.0842932"></polygon>
          <polygon id="Fill-23" fill="#FFFFFF" mask="url(#mask-4)" points="81.7368063 109.304712 128.791152 109.304712 128.791152 105.657906 81.7368063 105.657906"></polygon>
          <path d="M182.789948,137.590241 L131.773455,137.590241 C129.850576,137.590241 128.29178,136.03244 128.29178,134.10956 L128.29178,71.6313927 C128.29178,69.7095079 129.850576,68.1497173 131.773455,68.1497173 L182.789948,68.1497173 C184.712827,68.1497173 186.270628,69.7095079 186.270628,71.6313927 L186.270628,134.10956 C186.270628,136.03244 184.712827,137.590241 182.789948,137.590241" id="Fill-24" fill="#F5F5F5" mask="url(#mask-4)"></path>
          <path d="M142.465979,187.156466 L91.4952461,182.199555 C89.6350366,182.018508 88.2732042,180.364215 88.4542513,178.504005 L94.5223141,116.125314 C94.7023665,114.266099 96.3566597,112.903272 98.2178639,113.084319 L149.188597,118.042225 C151.048806,118.223272 152.409644,119.877565 152.228597,121.737775 L146.161529,184.115471 C145.980482,185.975681 144.326188,187.338508 142.465979,187.156466" id="Fill-25" fill="#F5F5F5" mask="url(#mask-4)"></path>
          <path d="M182.789948,137.590241 L131.773455,137.590241 C129.850576,137.590241 128.29178,136.03244 128.29178,134.10956 L128.29178,71.6313927 C128.29178,69.7095079 129.850576,68.1497173 131.773455,68.1497173 L182.789948,68.1497173 C184.712827,68.1497173 186.270628,69.7095079 186.270628,71.6313927 L186.270628,134.10956 C186.270628,136.03244 184.712827,137.590241 182.789948,137.590241" id="Fill-26" fill="#F5F5F5" mask="url(#mask-4)"></path>
          <path d="M142.465979,187.156466 L91.4952461,182.199555 C89.6350366,182.018508 88.2732042,180.364215 88.4542513,178.504005 L94.5223141,116.125314 C94.7023665,114.266099 96.3566597,112.903272 98.2178639,113.084319 L149.188597,118.042225 C151.048806,118.223272 152.409644,119.877565 152.228597,121.737775 L146.161529,184.115471 C145.980482,185.975681 144.326188,187.338508 142.465979,187.156466" id="Fill-27" fill="#F5F5F5" mask="url(#mask-4)"></path>
          <path d="M130.287874,124.222099 L80.9515393,138.108016 C79.1629529,138.611366 77.3057277,137.569848 76.802377,135.782257 L59.811801,75.4129895 C59.3084503,73.6253979 60.3499686,71.7681728 62.138555,71.264822 L111.47489,57.3789058 C113.263476,56.875555 115.120702,57.9170733 115.624052,59.7056597 L132.614628,120.073932 C133.117979,121.861524 132.076461,123.718749 130.287874,124.222099" id="Fill-28" fill="#F5F5F5" mask="url(#mask-4)"></path>
          <path d="M184.779476,139.57977 L133.762984,139.57977 C131.840105,139.57977 130.281309,138.021969 130.281309,136.099089 L130.281309,73.6209215 C130.281309,71.6990366 131.840105,70.1392461 133.762984,70.1392461 L184.779476,70.1392461 C186.702356,70.1392461 188.260157,71.6990366 188.260157,73.6209215 L188.260157,136.099089 C188.260157,138.021969 186.702356,139.57977 184.779476,139.57977" id="Fill-29" fill="#FFFFFF" mask="url(#mask-4)"></path>
          <path d="M184.779476,139.57977 L133.762984,139.57977 C131.840105,139.57977 130.281309,138.021969 130.281309,136.099089 L130.281309,73.6209215 C130.281309,71.6990366 131.840105,70.1392461 133.762984,70.1392461 L184.779476,70.1392461 C186.702356,70.1392461 188.260157,71.6990366 188.260157,73.6209215 L188.260157,136.099089 C188.260157,138.021969 186.702356,139.57977 184.779476,139.57977 Z" id="Stroke-30" stroke="#D9D9D9" strokeWidth="1.5" mask="url(#mask-4)"></path>
          <polygon id="Fill-31" fill="#F9BFCA" mask="url(#mask-4)" points="135.168586 124.916545 182.698429 124.916545 182.698429 76.7122513 135.168586 76.7122513"></polygon>
          <polygon id="Fill-32" fillOpacity="0.4" fill="#F9BFCA" style={{ mixBlendMode: "multiply" }} mask="url(#mask-4)" points="135.168487 76.7123508 135.168487 124.916644 182.69833 76.7123508"></polygon>
          <path d="M142.645733,100.324476 C142.645733,91.0821204 150.138298,83.589555 159.380654,83.589555 C168.62301,83.589555 176.115576,91.0821204 176.115576,100.324476 C176.115576,109.566832 168.62301,117.058403 159.380654,117.058403 C150.138298,117.058403 142.645733,109.566832 142.645733,100.324476" id="Fill-33" fill="#FFFFFF" mask="url(#mask-4)"></path>
          <path d="M149.240921,100.324476 L169.521183,100.324476 C169.521183,105.924005 164.980084,110.46411 159.380555,110.46411 C153.780031,110.46411 149.240921,105.924005 149.240921,100.324476" id="Fill-34" fill="#F9BFCA" mask="url(#mask-4)"></path>
          <path d="M156.214916,92.5859058 C156.214916,93.3817173 155.570309,94.0253298 154.775492,94.0253298 C153.980675,94.0253298 153.335073,93.3817173 153.335073,92.5859058 C153.335073,91.791089 153.980675,91.1474764 154.775492,91.1474764 C155.570309,91.1474764 156.214916,91.791089 156.214916,92.5859058" id="Fill-35" fill="#F9BFCA" mask="url(#mask-4)"></path>
          <path d="M165.752618,92.5859058 C165.752618,93.3817173 165.10801,94.0253298 164.313194,94.0253298 C163.518377,94.0253298 162.872775,93.3817173 162.872775,92.5859058 C162.872775,91.791089 163.518377,91.1474764 164.313194,91.1474764 C165.10801,91.1474764 165.752618,91.791089 165.752618,92.5859058" id="Fill-36" fill="#F9BFCA" mask="url(#mask-4)"></path>
          <path d="M144.455508,189.145995 L93.4847749,184.189084 C91.6245654,184.008037 90.262733,182.353743 90.4437801,180.493534 L96.5118429,118.114843 C96.6918953,116.255628 98.3461885,114.892801 100.207393,115.073848 L151.178126,120.031754 C153.038335,120.212801 154.399173,121.867094 154.218126,123.727304 L148.151058,186.105 C147.97001,187.965209 146.315717,189.328037 144.455508,189.145995" id="Fill-37" fill="#FFFFFF" mask="url(#mask-4)"></path>
          <path d="M144.455508,189.145995 L93.4847749,184.189084 C91.6245654,184.008037 90.262733,182.353743 90.4437801,180.493534 L96.5118429,118.114843 C96.6918953,116.255628 98.3461885,114.892801 100.207393,115.073848 L151.178126,120.031754 C153.038335,120.212801 154.399173,121.867094 154.218126,123.727304 L148.151058,186.105 C147.97001,187.965209 146.315717,189.328037 144.455508,189.145995 Z" id="Stroke-38" stroke="#D9D9D9" strokeWidth="1.5" mask="url(#mask-4)"></path>
          <polygon id="Fill-39" fill="#F9BFCA" mask="url(#mask-4)" points="143.707545 174.341115 96.4005288 169.739335 101.067963 121.762843 148.37299 126.363628"></polygon>
          <path d="M148.373387,126.363131 C147.850141,127.330042 96.4009267,169.738838 96.4009267,169.738838 L101.067366,121.762346 L148.373387,126.363131 Z" id="Fill-40" fillOpacity="0.4" fill="#F9BFCA" style={{ mixBlendMode: "multiply" }} mask="url(#mask-4)"></path>
          <path d="M132.277403,126.211628 L82.9410681,140.097545 C81.1524817,140.600895 79.2952565,139.559377 78.7919058,137.771785 L61.8013298,77.4025183 C61.2979791,75.6149267 62.3394974,73.7577016 64.1280838,73.2543508 L113.464419,59.3684346 C115.253005,58.8650838 117.11023,59.9066021 117.613581,61.6951885 L134.604157,122.063461 C135.107508,123.851052 134.06599,125.708277 132.277403,126.211628" id="Fill-41" fill="#FFFFFF" mask="url(#mask-4)"></path>
          <path d="M132.277403,126.211628 L82.9410681,140.097545 C81.1524817,140.600895 79.2952565,139.559377 78.7919058,137.771785 L61.8013298,77.4025183 C61.2979791,75.6149267 62.3394974,73.7577016 64.1280838,73.2543508 L113.464419,59.3684346 C115.253005,58.8650838 117.11023,59.9066021 117.613581,61.6951885 L134.604157,122.063461 C135.107508,123.851052 134.06599,125.708277 132.277403,126.211628 Z" id="Stroke-42" stroke="#D9D9D9" strokeWidth="1.5" mask="url(#mask-4)"></path>
          <polygon id="Fill-43" fill="#ED3154" mask="url(#mask-4)" points="126.512843 112.60166 80.760644 125.47789 67.701377 79.0771047 113.453576 66.1998796"></polygon>
          <polygon id="Fill-44" fillOpacity="0.7" fill="#ED3154" style={{ mixBlendMode: "multiply" }} mask="url(#mask-4)" points="67.7012775 79.0769058 80.7605445 125.478686 113.453476 66.2006754"></polygon>
          <path d="M87.7394136,81.8571728 C88.9699372,81.5179581 90.2273194,81.4503141 91.4886806,81.5338743 C92.7400942,81.615445 93.8572147,81.3060733 94.8867958,80.5918325 C96.106377,79.7423037 97.406534,79.0678534 98.8857487,78.765445 C101.700932,78.1884817 103.937162,79.1046597 105.624283,81.3767016 C107.113445,83.3791623 107.731194,85.7019372 107.949047,88.1510471 C108.099257,89.8341885 107.969937,91.4964398 107.660565,93.1576963 C107.237791,95.4376963 107.294492,97.7346073 107.544178,100.031518 C107.803812,102.416963 108.16889,104.79445 108.111194,107.204764 C108.087319,108.167696 107.620775,108.831204 106.818995,109.045079 C105.989361,109.265916 105.210461,108.927696 104.758838,108.069215 C103.869518,106.380105 103.020984,104.670105 102.140618,102.975026 C101.90088,102.515445 101.630304,102.066806 101.335853,101.640052 C100.697215,100.708953 99.7511937,100.38466 98.6768482,100.695026 C97.6661675,100.988482 96.9698325,101.81712 96.9996754,102.916335 C97.043445,104.591518 97.1866911,106.263717 97.2891518,107.937906 C97.334911,108.716806 97.406534,109.494712 97.4274241,110.274607 C97.4522932,111.162932 96.9688377,111.810524 96.1720314,112.036335 C95.3911414,112.256178 94.6012984,111.987592 94.1924503,111.238534 C93.3498848,109.690681 92.513288,108.135864 91.7662199,106.539267 C90.6361675,104.127958 89.5488901,101.702723 87.8239686,99.6286387 C86.8829215,98.4975916 85.8981047,97.3824607 85.107267,96.1489529 C83.5942304,93.7953403 82.6770576,91.2139267 82.7486806,88.366911 C82.8203037,85.5835602 83.9901466,83.4686911 86.5994136,82.2829319 C86.9684712,82.1158115 87.3584188,81.9984293 87.7394136,81.8571728" id="Fill-45" fill="#FFFFFF" mask="url(#mask-4)"></path>
          <path d="M119.916759,162.312623 L121.815764,142.787387 L118.130162,145.948749 C117.569115,146.410319 116.839953,146.666969 116.095869,146.595346 C114.561942,146.446131 113.474665,145.073356 113.619901,143.586183 C113.697492,142.795346 114.086445,142.176602 114.599743,141.710058 L122.660319,135.079953 C123.425293,134.450267 124.442937,134.126969 125.511314,134.231419 C127.604298,134.434351 129.156131,136.322414 128.952204,138.414403 L126.56477,162.95922 C126.392675,164.725921 124.755293,166.114613 122.942832,165.939534 C121.082623,165.757492 119.744665,164.07833 119.916759,162.312623" id="Fill-46" fill="#FFFFFF" mask="url(#mask-4)"></path>
          <path d="M214.167702,149.613859 C216.918225,162.091188 209.032728,174.434225 196.555398,177.184749 C184.078068,179.934277 171.735031,172.049775 168.984508,159.572445 C166.234979,147.095115 174.120476,134.752079 186.596812,132.001555 C199.074141,129.251031 211.417178,137.136529 214.167702,149.613859" id="Fill-47" fill="#F0A4B2" mask="url(#mask-4)"></path>
          <path d="M212.429848,148.484005 C215.180372,160.961335 207.294874,173.304372 194.817545,176.054895 C182.340215,178.804424 169.997178,170.919921 167.246654,158.442592 C164.496131,145.966257 172.381628,133.622225 184.858958,130.871702 C197.336288,128.122173 209.679325,136.00767 212.429848,148.484005" id="Fill-48" fill="#EA3154" mask="url(#mask-4)"></path>
          <path d="M179,150.681339 C179,146.991744 181.952789,144 185.595638,144 C187.353065,144 188.947994,144.695625 190.13,145.830885 C191.313119,144.695625 192.906935,144 194.663249,144 C198.307211,144 201.26,146.991744 201.26,150.681339 C201.26,154.372047 191.313119,165.021231 190.13,163.887084 C188.947994,165.021231 179,154.372047 179,150.681339" id="Fill-49" fill="#FFFFFF" mask="url(#mask-4)"></path>
        </g>
      </g>
    </StyledIcon>
  </Wrapper>
)
