import React from "react";
import styled from "styled-components";

const StyledIcon = styled.svg`
  height: 190px;
  .cls-1 {
    fill: ${props => props.theme.primary};
  }
  .cls-2,
  .cls-5,
  .cls-8 {
    fill:#fff;
  }
  .cls-3 {
    fill:#f5f5f5;
  }
  .cls-4 {
    fill: none;
  }
  .cls-4,
  .cls-5,
  .cls-8 {
    stroke:#d9d9d9;
    stroke-width:1.5px;
  }
  .cls-4,
  .cls-8 {
    stroke-miterlimit:10;
  }
  .cls-5 {
    stroke-linecap:round;
    stroke-linejoin:round;
  }
  .cls-6 {
    fill:#f9bfca;
  }
  .cls-7 {
    fill:#f48398;
    opacity:0.58;
  }
`;

const Wrapper = styled.span`
  display: block;
`;

export default ({ onClick }) => (
  <Wrapper onClick={onClick}>
    <StyledIcon
      viewBox="0 0 202.18 205.41"
      height="190"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle className="cls-1" transform="translate(-20 48.28) rotate(-45)" cx="48.28" cy="48.28" r="48.28" />
      <path className="cls-2" d="M24.54,53.84V45.15H21.87a.88.88,0,1,1,0-1.75h7.36a.88.88,0,1,1,0,1.75H26.55v8.69a1,1,0,0,1-1,1A1,1,0,0,1,24.54,53.84Z" />
      <path className="cls-2" d="M40.89,53.94a1,1,0,0,1-1,.9,1.1,1.1,0,0,1-.84-.42l-2.69-3.91H34.07v3.33a1,1,0,0,1-1,1,1,1,0,0,1-1-1V44.46a1,1,0,0,1,1.07-1.06h4A3.46,3.46,0,0,1,40.84,47a3.19,3.19,0,0,1-2.37,3.27l2.23,3.09A1,1,0,0,1,40.89,53.94Zm-6.82-5.18h2.75a1.81,1.81,0,1,0,0-3.61H34.07Z" />
      <path className="cls-2" d="M53.06,53.82a1,1,0,0,1-1,1,1,1,0,0,1-.94-.64l-.6-1.64H45l-.6,1.64a1,1,0,0,1-.94.64,1,1,0,0,1-1-1,1.45,1.45,0,0,1,.08-.36l3.59-9a1.78,1.78,0,0,1,3.3,0l3.59,9A1.45,1.45,0,0,1,53.06,53.82Zm-3.21-3-2.11-5.73-2.11,5.73Z" />
      <path className="cls-2" d="M54,49.08a5.73,5.73,0,0,1,5.93-5.87,5.18,5.18,0,0,1,4,1.69.87.87,0,0,1,.24.63.95.95,0,0,1-1.66.61A3.43,3.43,0,0,0,59.9,45a4.11,4.11,0,0,0,0,8.2A3.47,3.47,0,0,0,62.51,52a.93.93,0,0,1,.7-.32.94.94,0,0,1,.94.93.87.87,0,0,1-.24.63,5.14,5.14,0,0,1-4,1.68A5.73,5.73,0,0,1,54,49.08Z" />
      <path className="cls-2" d="M75.21,53.89a.93.93,0,0,1-.93,1,.91.91,0,0,1-.7-.29L69.39,50l-1,1.07v2.76a1,1,0,0,1-2,0V44.3a1,1,0,0,1,2,0v4.54l4.82-5.23a.91.91,0,0,1,.71-.31,1,1,0,0,1,.94.92.83.83,0,0,1-.26.63l-3.84,4L75,53.26A1,1,0,0,1,75.21,53.89Z" />
      <rect className="cls-3" x="65.81" y="204.16" width="85.53" height=".97" />
      <polygon className="cls-3" points="65.7 148.07 65.23 155.6 60.37 155.07 56.31 147.96" />
      <polygon className="cls-3" points="149.46 141.92 146.83 134.48 154.88 134.71 158.71 141.64" />
      <path className="cls-4" d="m200.29 107.19c-0.82-1-1.74-1.75-3-1.57l-25.94 3.48a3.67 3.67 0 0 0 1 7.27l13.5-1.77-18.63 15.86a16.86 16.86 0 0 1 -11 4l-43.15-0.22-28.61 14.08-25.53-0.49a29.15 29.15 0 0 0 -17.53 5.49l-20.34 14.74c-1.64 1.19-1.18 2.63 0 4.27s2.65 2.86 4.29 1.67l20.35-14.74a21.69 21.69 0 0 1 13.08 -4.1l27.31 0.53 28.71-14.1 41.43 0.21a24.1 24.1 0 0 0 15.77 -5.75l18.69-15.92-4.34 14.37a3.67 3.67 0 0 0 5.88 3.87 3.76 3.76 0 0 0 1.13 -1.71l8-26.33c0.33-1.2-0.26-2.18-1.07-3.14z" />
      <path className="cls-5" d="M152.11,153.2a43.74,43.74,0,1,0-64.53,38.47c.13.08-5,10-5,10a2.05,2.05,0,0,0,1.83,3H132.2a2.05,2.05,0,0,0,1.89-2.84s-4.65-10.29-4.42-10.41A43.73,43.73,0,0,0,152.11,153.2Z" />
      <circle className="cls-6" transform="translate(-65.72 223.56) rotate(-76.72)" cx="108.38" cy="153.3" r="36.74" />
      <path className="cls-5" d="M124.55,193.86a43.86,43.86,0,0,1-31.13.46" />
      <path className="cls-7" d="M143,155.4a36.74,36.74,0,0,0-61.66-27,36.74,36.74,0,1,1,51.92,51.92A36.63,36.63,0,0,0,143,155.4Z" />
      <circle className="cls-2" cx="108.38" cy="153.3" r="25.56" />
      <path className="cls-1" d="M114.39,149.57l4.16-9.79a2.88,2.88,0,1,0-5.29-2.26L109.21,147a6.82,6.82,0,1,0,5.18,2.54Z" />
      <path className="cls-2" d="m111.76 153.67a2.73 2.73 0 1 1 -2.76 -2.67 2.72 2.72 0 0 1 2.76 2.67z" />
      <line className="cls-8" x1="84.76" x2="94.33" y1="153.51" y2="153.51" />
      <line className="cls-8" x1="122.05" x2="131.62" y1="153.51" y2="153.51" />
      <path className="cls-2" transform="translate(207.23 -9.84) rotate(90)" d="m92.27 50.33h6a26.53 26.53 0 0 1 26.53 26.53v43.68a26.53 26.53 0 0 1 -26.53 26.53h-6v-96.74z" />
      <path className="cls-8" d="M130.37,109H86.7A20.53,20.53,0,0,1,66.17,88.43H150.9A20.53,20.53,0,0,1,130.37,109Z" />
    </StyledIcon>
  </Wrapper>
)