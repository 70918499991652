import React from "react";
import styled from "styled-components";

const StyledIcon = styled.svg`
  height: 187px;
`;

const Wrapper = styled.span`
  display: block;
`;

export default ({ onClick }) => (
  <Wrapper onClick={onClick}>
    <StyledIcon
      viewBox="0 0 170 190"
      height="180"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <polygon id="j" points="0 0.81904 89.489 0.81904 89.489 90.307 0 90.307" />
        <polyline id="d" points="8.0384 1.7044 0.41059 66.036 52.886 109.46 71.38 110.14 116.67 73.827 126.05 44.179 112.59 20.926 51.39 0.70254" />
        <polyline id="c" points="8.0384 1.7044 0.41059 66.036 52.886 109.46 71.38 110.14 116.67 73.827 126.05 44.179 112.59 20.926 51.39 0.70254" />
        <polyline id="b" points="8.0384 1.7044 0.41059 66.036 52.886 109.46 71.38 110.14 116.67 73.827 126.05 44.179 112.59 20.926 51.39 0.70254" />
        <polyline id="a" points="8.0384 1.7044 0.41059 66.036 52.886 109.46 71.38 110.14 116.67 73.827 126.05 44.179 112.59 20.926 51.39 0.70254" />
        <polyline id="i" points="8.0384 1.7044 0.41059 66.036 52.886 109.46 71.38 110.14 116.67 73.827 126.05 44.179 112.59 20.926 51.39 0.70254" />
        <polygon id="h" points="8.0384 1.7044 0.41059 66.036 52.886 109.46 71.38 110.14 116.67 73.827 126.05 44.179 112.59 20.926 51.39 0.70254" />
        <polygon id="g" points="8.0384 1.7044 0.41059 66.036 52.886 109.46 71.38 110.14 116.67 73.827 126.05 44.179 112.59 20.926 51.39 0.70254" />
        <polygon id="f" points="8.0384 1.7044 0.41059 66.036 52.886 109.46 71.38 110.14 116.67 73.827 126.05 44.179 112.59 20.926 51.39 0.70254" />
        <polygon id="e" points="8.0384 1.7044 0.41059 66.036 52.886 109.46 71.38 110.14 116.67 73.827 126.05 44.179 112.59 20.926 51.39 0.70254" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(0 .10844)">
          <mask id="o" fill="white">
            <use xlinkHref="#j" />
          </mask>
          <path d="m89.489 45.563c0 24.712-20.032 44.745-44.745 44.745-24.711 0-44.745-20.032-44.745-44.745 0-24.711 20.033-44.744 44.745-44.744 24.712 0 44.745 20.032 44.745 44.744" fill="#1B9ED8" mask="url(#o)" />
          <path d="m23.518 49.93c1.0186 0 1.6229-0.55146 1.6229-1.4653 0-0.7878-0.55795-1.4496-1.6229-1.4496h-3.0548v2.9149h3.0548zm-0.079707-4.5378c0.95371 0 1.495-0.56629 1.495-1.3541 0-0.7878-0.54127-1.3708-1.495-1.3708h-2.9751v2.7249h2.9751zm-4.8371 5.1838v-8.5546c0-0.63024 0.35034-0.97688 0.98707-0.97688h4.2319c1.9408 0 3.0233 1.1975 3.0233 2.6785 0 1.3226-0.8601 2.2059-1.8462 2.4098 1.1289 0.17424 2.0529 1.2929 2.0529 2.584 0 1.6229-1.0983 2.8361-3.0873 2.8361h-4.3746c-0.63673 0-0.98707-0.34663-0.98707-0.97688z" fill="#fff" />
          <path d="m34.18 42.51c-2.1475 0-3.5164 1.6229-3.5164 3.7972 0 2.1577 1.3689 3.7963 3.5164 3.7963 2.1317 0 3.5164-1.6386 3.5164-3.7963 0-2.1743-1.3847-3.7972-3.5164-3.7972m0-1.6386c3.1818 0 5.4257 2.3004 5.4257 5.4359 0 3.1345-2.2439 5.4349-5.4257 5.4349-3.1827 0-5.4257-2.3004-5.4257-5.4349 0-3.1355 2.2429-5.4359 5.4257-5.4359" fill="#fff" />
          <path d="m46.672 42.51c-2.1475 0-3.5164 1.6229-3.5164 3.7972 0 2.1577 1.3689 3.7963 3.5164 3.7963 2.1317 0 3.5164-1.6386 3.5164-3.7963 0-2.1743-1.3847-3.7972-3.5164-3.7972m0-1.6386c3.1818 0 5.4257 2.3004 5.4257 5.4359 0 3.1345-2.2439 5.4349-5.4257 5.4349-3.1827 0-5.4257-2.3004-5.4257-5.4349 0-3.1355 2.2429-5.4359 5.4257-5.4359" fill="#fff" />
          <path d="m53.754 49.898c0-0.48751 0.39854-0.88141 0.87493-0.88141 0.20761 0 0.39854 0.062098 0.54127 0.17239 0.73127 0.53571 1.6386 0.91478 2.7527 0.91478 1.5274 0 2.1317-0.74054 2.1317-1.4338 0-0.94629-1.1298-1.229-2.419-1.5608-1.7341-0.42541-3.7703-0.92961-3.7703-3.1503 0-1.7332 1.5432-3.0724 4.0261-3.0724 1.241 0 2.2429 0.31512 3.2773 0.96112 0.2382 0.1418 0.39761 0.40966 0.39761 0.70902 0 0.47268-0.39761 0.86659-0.87585 0.86659-0.19093 0-0.36517-0.063024-0.49307-0.15756-0.76278-0.5042-1.6544-0.74054-2.4654-0.74054-1.2568 0-1.9565 0.51995-1.9565 1.3078 0 0.81932 1.0816 1.0872 2.3384 1.4023 1.7499 0.44024 3.8343 0.97595 3.8343 3.2606 0 1.7489-1.241 3.2458-4.1049 3.2458-1.5747 0-2.8-0.44117-3.7388-1.1502-0.20761-0.15756-0.35034-0.40873-0.35034-0.69327" fill="#fff" />
          <path d="m66.358 50.717v-8.0504h-2.482c-0.44488 0-0.82673-0.36146-0.82673-0.80356 0-0.45693 0.38185-0.81932 0.82673-0.81932h6.8261c0.44488 0 0.82673 0.36239 0.82673 0.81932 0 0.4421-0.38185 0.80356-0.82673 0.80356h-2.482v8.0504c0 0.5042-0.41429 0.92961-0.92312 0.92961-0.52551 0-0.93888-0.42541-0.93888-0.92961" fill="#fff" />
          <g transform="translate(62.098 88.049)">
            <path d="m0.50234 39.379c0-1.2559 0.61078-2.4339 1.6377-3.1577l49.271-34.743c1.3467-0.94907 3.1466-0.9398 4.484 0.022244l48.466 34.889c1.0084 0.72756 1.6062 1.8935 1.6062 3.1364" fill="#fff" />
            <path d="m0.50234 39.379c0-1.2559 0.61078-2.4339 1.6377-3.1577l49.271-34.743c1.3467-0.94907 3.1466-0.9398 4.484 0.022244l48.466 34.889c1.0084 0.72756 1.6062 1.8935 1.6062 3.1364" stroke="#D9D9D9" strokeWidth="1.5" />
          </g>
          <path d="m83.771 107.33c0.43839 0 17.238 1.5747 17.238 1.5747l-0.43746-5.4247-15.05-0.26229-1.7508 4.1123z" fill="#fff" />
          <g transform="translate(62.098 126.98)">
            <path d="m105.97 0.59873v56.941c0 2.1326-1.7295 3.863-3.864 3.863h-97.737c-2.1345 0-3.864-1.7304-3.864-3.863v-57.087" fill="#fff" />
            <path d="m105.97 0.59873v56.941c0 2.1326-1.7295 3.863-3.864 3.863h-97.737c-2.1345 0-3.864-1.7304-3.864-3.863v-57.087" stroke="#D9D9D9" strokeWidth="1.5" />
          </g>
        </g>
        <g transform="translate(52.829 52.011)">
          <mask id="m" fill="white">
            <use xlinkHref="#d" />
          </mask>
          <path d="m50.744 100.91l-34.507-5.1791c-1.0723-0.16127-1.8101-1.1595-1.6488-2.2309l5.2366-34.888c0.15941-1.0714 1.1595-1.8092 2.23-1.6488l34.508 5.18c1.0714 0.16034 1.8092 1.1595 1.6488 2.23l-5.2366 34.888c-0.16127 1.0714-1.1595 1.8092-2.2309 1.6488" fill="#1B9ED8" mask="url(#m)" />
          <mask id="l" fill="white">
            <use xlinkHref="#c" />
          </mask>
          <path d="m58.121 60.882c-0.23356 0-0.46898-0.011122-0.70624-0.033366l-34.767-3.2828c-4.129-0.39298-7.1718-4.0688-6.7835-8.1969l3.319-35.151c0.36888-3.8862 3.5878-6.8159 7.4897-6.8159 0.23356 0 0.46898 0.011122 0.70624 0.033366l34.767 3.2828c4.129 0.39112 7.1718 4.0679 6.7835 8.196l-3.319 35.15c-0.36795 3.8871-3.5878 6.8178-7.4897 6.8178" fill="#fff" mask="url(#l)" />
          <mask id="k" fill="white">
            <use xlinkHref="#b" />
          </mask>
          <path d="m57.935 55.311l-34.764-3.2828c-1.0798-0.10102-1.8713-1.0594-1.7693-2.1391l3.319-35.146c0.10102-1.0798 1.0594-1.8722 2.1382-1.7702l34.764 3.2828c1.0798 0.10195 1.8713 1.0594 1.7702 2.1391l-3.319 35.147c-0.10288 1.0788-1.0594 1.8713-2.1391 1.7693" fill="#BFE1ED" mask="url(#k)" />
          <mask id="t" fill="white">
            <use xlinkHref="#a" />
          </mask>
          <path d="m105.7 58.88l-34.315 3.458c-1.0659 0.10844-2.0159-0.66824-2.1234-1.7341l-3.4979-34.692c-0.10659-1.0659 0.6701-2.0168 1.735-2.1234l34.315-3.4599c1.0649-0.10751 2.0159 0.66917 2.1234 1.7341l3.4969 34.694c0.10751 1.0649-0.66917 2.0159-1.7341 2.1234" fill="#1B9ED8" mask="url(#t)" />
          <mask id="s" fill="white">
            <use xlinkHref="#i" />
          </mask>
          <path d="m95.016 110.57l-34.092-6.4331c-1.0594-0.2002-1.7554-1.2206-1.5552-2.2781l6.5045-34.47c0.19927-1.0575 1.2197-1.7536 2.2781-1.5552l34.093 6.435c1.0575 0.19927 1.7545 1.2188 1.5552 2.2781l-6.5054 34.468c-0.19927 1.0594-1.2188 1.7554-2.2781 1.5552" fill="#BFE1ED" mask="url(#s)" />
          <mask id="r" fill="white">
            <use xlinkHref="#h" />
          </mask>
          <path d="m39.926 34.514l3.3227 0.29659c2.6359 0.23634 4.2467-1.4282 4.4182-3.3375 0.2178-2.445-1.3087-3.8908-3.7518-4.1096l-3.3227-0.29751-0.66639 7.448zm-5.307-10.406c0.17888-1.9862 1.7675-2.9603 3.3718-2.8176l7.5981 0.68029c4.776 0.42727 9.044 3.927 8.4935 10.076-0.47361 5.308-4.0688 8.9142-9.9514 8.3887l-4.6972-0.41985-0.50883 5.6907c-0.19463 2.1771-1.5923 3.1679-3.3857 3.0066-1.7962-0.16034-2.9955-1.3838-2.8009-3.56l1.8805-21.045z" fill="#fff" mask="url(#r)" />
          <mask id="q" fill="white">
            <use xlinkHref="#g" />
          </mask>
          <path d="m78.867 31.513c-0.21966-2.1743 0.96483-3.4117 2.7592-3.5924 1.7925-0.18166 3.2013 0.79244 3.4209 2.9677l1.8601 18.389 6.6389-0.67195c1.9454-0.19649 3.1966 0.75722 3.3774 2.5488 0.17424 1.7183-0.85268 2.976-2.799 3.1735l-9.7271 0.98429c-1.6034 0.1622-3.2031-0.79337-3.4042-2.7777l-2.1261-21.021z" fill="#fff" mask="url(#q)" />
          <mask id="p" fill="white">
            <use xlinkHref="#f" />
          </mask>
          <path d="m37.712 72.546l-0.076-0.010195-4.5924 9.4954 6.4646 0.83693-1.7962-10.322zm-5.1606-1.9436c1.4913-2.8639 2.7768-5.3256 5.8576-4.927 3.1549 0.4078 3.7703 3.116 4.4859 6.2663l3.8602 16.775c0.5181 2.2707 0.53941 3.0076 0.42171 3.9214-0.19649 1.5191-1.5413 2.3532-3.2912 2.1271-2.0159-0.26137-2.4941-1.6386-2.8435-3.4256l-0.4458-2.5312-10.116-1.3087-1.0742 2.3347c-0.79151 1.6396-1.6062 2.85-3.622 2.5886-1.7499-0.22615-2.838-1.3745-2.6415-2.8945 0.11863-0.91385 0.32624-1.6201 1.406-3.6841l8.0032-15.242z" fill="#fff" mask="url(#p)" />
          <mask id="n" fill="white">
            <use xlinkHref="#e" />
          </mask>
          <path d="m78.796 89.346l-3.9807-8.0588c-1.4329-3.0307-1.8981-3.8936-1.6701-5.1402 0.29844-1.622 1.9324-2.4524 3.7425-2.1197 1.6581 0.304 2.2364 1.6192 2.7119 2.6424l3.3431 7.2793 5.7093-5.6203c0.80634-0.7878 1.8129-1.8129 3.4728-1.5089 1.8101 0.33273 3.0428 1.6887 2.7453 3.3097-0.228 1.2466-0.97039 1.8889-3.3839 4.2143l-6.5796 6.1226-1.7573 9.5816c-0.39483 2.1493-1.8768 3.0076-3.6499 2.6822-1.7721-0.32439-2.8537-1.6535-2.4598-3.8028l1.7563-9.5816z" fill="#fff" mask="url(#n)" />
        </g>
        <g transform="translate(62.098 110.4)">
          <g transform="translate(0 14.829)">
            <path d="m0.87252 0.47296l40.891 33.905" fill="#fff" />
            <path d="m0.87252 0.47296l40.891 33.905" stroke="#D9D9D9" strokeWidth="1.5" />
          </g>
          <g transform="translate(.92683 41.707)">
            <path d="m103.58 34.709l-46.215-32.83c-2.9798-2.47-7.2969-2.4663-10.274 0.0083415l-46.408 33.421" fill="#fff" />
            <path d="m103.58 34.709l-46.215-32.83c-2.9798-2.47-7.2969-2.4663-10.274 0.0083415l-46.408 33.421" stroke="#D9D9D9" strokeWidth="1.5" />
          </g>
          <g transform="translate(64.878 15.756)">
            <path d="m40.615 0.22401l-39.876 33.228" fill="#fff" />
            <path d="m40.615 0.22401l-39.876 33.228" stroke="#D9D9D9" strokeWidth="1.5" />
          </g>
          <polygon points="38.208 42.115 33.475 41.368 7.6247 19.946 7.9046 17.642" fill="#BFE1ED" fillOpacity=".5" style={{ mixBlendMode: "multiply" }} />
          <polygon points="63.796 46.617 65.891 48.076 92.768 25.734 93.153 23.132" fill="#BFE1ED" fillOpacity=".5" style={{ mixBlendMode: "multiply" }} />
          <polygon points="70.931 45.88 67.118 49.039 104.81 75.619" fill="#F5F5F5" />
          <polygon points="36.051 45.516 39.864 48.674 2.172 75.255" fill="#F5F5F5" />
          <polygon points="93.054 0.84768 78.526 2.3325 79.734 4.1806 92.882 2.6874" fill="#fff" />
        </g>
      </g>
    </StyledIcon>
  </Wrapper>
)
